import React from 'react'
import moment from 'moment'
import NavigationBar from '../../component/NavigationBar'
import VButton from '../../component/VButton'
import VLabel from '../../component/VLabel'
import VMenu from '../../component/VMenu'
import { Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons';
import { getQuery, GenderMap } from '../../utils/utils';
import ProfileCreation from './ProfileCreation'
import ProfileDiary from './ProfileDiary'
import ProfileFollow from './ProfileFollow'
import ProfileFollower from './ProfileFollower'
import defaultAvatar from '../../resources/avatar.png';
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');

class Profile extends React.Component {
  constructor(props) {
    super(props)
    const search = this.props.location.search
    const params = getQuery(search)
    this.state = {
      current: 'creation',
      userInfo: {},
      id: params.id,
      loadingProfile: false
    }
  }
  componentDidMount() {
    this.fetchProfile()
  }

  fetchProfile() {
    const { id } = this.state
    const that = this
    this.setState({
      loadingProfile: true
    })
    const userQuery = new AV.Query('_User');
    if (id && (id !== AV.User.current().id)) {
      userQuery.equalTo('objectId', id)
    } else {
      userQuery.equalTo('objectId', AV.User.current().id)
    }
    userQuery.first().then(user => {
      that.setState({
        userInfo: {
          ...user.attributes,
          age: `${moment().diff(user.attributes.birthday, 'year')}岁`
        }
      })
    }).finally(() => {
      that.setState({
        loadingProfile: false
      })
    })
  }
  handleClick(e) {
    this.setState({
      current: e.key
    })
  }
  getRender(current) {
    if (current === 'creation') {
      return <ProfileCreation {...this.props} />
    } else if (current === 'diary') {
      return <ProfileDiary {...this.props} />
    } else if (current === 'follow') {
      return <ProfileFollow {...this.props} />
    } else {
      return <ProfileFollower {...this.props} />
    }
  }
  handleMenuClick(key) {
    this.setState({
      current: key
    })
  }
  navigateToEditProfile = () => {
    this.props.history.push('/profileedit')
  }
  logout = () => {
    AV.User.logOut()
    localStorage.removeItem('avatar')
    this.props.history.push('/')
  }
  render() {
    const { current, userInfo, id, loadingProfile } = this.state
    const { username, gender = 0, avatar, location, brief, age } = userInfo
    const { isMobile } = this.props
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ?
          //Mobile
          <>
            <Spin spinning={loadingProfile}>
              <div className="container-wrap padding-10">
                <div className="profile-wrap-mobile">
                  <div className="profile-left">
                    <div className="flex-sb-column">
                      <img className={isMobile ? "profile-avatar-mobile" : "profile-avatar"} src={avatar ? avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                      <VButton title="退出" onClick={this.logout} />
                    </div>
                    <div className="profile-info-wrap">
                      <div className="flex-sb-center">
                        <div className="profile-name">{username}</div>
                        {
                          (!id || (id === AV.User.current().id)) &&
                          <div className="flex-sb lh37 ml10 cursor-pointer" onClick={this.navigateToEditProfile}>
                            <EditOutlined className="fs14 theme-color lh37" />
                            <div className="fs14 theme-color">编辑</div>
                          </div>
                        }
                      </div>
                      <div className="profile-age-wrap">
                        <div className="profile-age">{age}</div>
                        <div className="profile-age">{GenderMap[gender].text}</div>
                        <div className="profile-age">{location}</div>
                      </div>
                      <div className="profile-desc">{brief}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="profile-menu-container-wrap padding-10">
              <VMenu className="profile-menu flex-sb">
                <VLabel fontSizeClass="fs16" title="我的创作" showLine={current === 'creation'} onClick={() => this.handleMenuClick('creation')}></VLabel>
                <VLabel fontSizeClass="fs16" title="我的日记" showLine={current === 'diary'} onClick={() => this.handleMenuClick('diary')}></VLabel>
                <VLabel fontSizeClass="fs16" title="我的关注" showLine={current === 'follow'} onClick={() => this.handleMenuClick('follow')}></VLabel>
                <VLabel fontSizeClass="fs16" title="关注我的" showLine={current === 'follower'} onClick={() => this.handleMenuClick('follower')}></VLabel>
              </VMenu>
              {
                this.getRender(current)
              }
            </div>
          </>
          :
          //PC
          <>
            <Spin spinning={loadingProfile}>
              <div className="container-wrap">
                <div className="profile-wrap">
                  <div className="profile-left">
                    <div>
                      <img className="profile-avatar" src={avatar ? avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                    </div>
                    <div className="profile-info-wrap">
                      <div className="flex-sb-center">
                        <div className="profile-name">{username}</div>
                        {
                          (!id || (id === AV.User.current().id)) &&
                          <div className="flex-sb lh37 ml10 cursor-pointer" onClick={this.navigateToEditProfile}>
                            <EditOutlined className="fs14 theme-color lh37" />
                            <div className="fs14 theme-color">编辑</div>
                          </div>
                        }
                      </div>
                      <div className="profile-age-wrap">
                        <div className="profile-age">{age}</div>
                        <div className="profile-age">{GenderMap[gender].text}</div>
                        <div className="profile-age">{location}</div>
                      </div>
                      <div className="profile-desc">{brief}</div>
                    </div>
                  </div>
                  <div className="profile-post" >
                    <VButton title="退出" onClick={this.logout} />
                  </div>
                </div>
              </div>
            </Spin>
            <div className="profile-menu-container-wrap">
              <VMenu className="profile-menu">
                <VLabel title="我的创作" backgroundClassName="mr50" showLine={current === 'creation'} onClick={() => this.handleMenuClick('creation')}></VLabel>
                <VLabel title="我的日记" backgroundClassName="mr50" showLine={current === 'diary'} onClick={() => this.handleMenuClick('diary')}></VLabel>
                <VLabel title="我的关注" backgroundClassName="mr50" showLine={current === 'follow'} onClick={() => this.handleMenuClick('follow')}></VLabel>
                <VLabel title="关注我的" showLine={current === 'follower'} onClick={() => this.handleMenuClick('follower')}></VLabel>
              </VMenu>
              {
                this.getRender(current)
              }
            </div>
          </>
        }
      </div>
    )
  }
}

export default HocMedia(Profile)