import React from 'react';
import styles from './index.module.css';

export default class VMenu extends React.Component {
  render() {
    const {className, style} = this.props;
    return (
      <div className={`${styles.menu} ${className}`} style={style}>
        {this.props.children}
      </div>
    )
  }
}