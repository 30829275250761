import React from 'react'
import NavigationBar from '../component/NavigationBar'
import VButton from '../component/VButton'
import VLabel from '../component/VLabel'
import PostPictureModal from '../component/PostPictureModal'
import CreationDetailModal from '../component/CreationDetailModal'
import SigninModal from '../component/SigninModal'
import { List, Avatar, Spin, message, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { getQuery } from '../utils/utils';
import HocMedia from '../utils/HocMedia'
const { Paragraph } = Typography
const AV = require('leancloud-storage');
class ChallengeDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loadingDetail: false,
      loadingList: false,
      visibleCreation: false,
      joinerNumber: 0,
      viewNumber: 0
    }
  }
  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchList()
    this.fetchChallenge()
  }
  //1. 路由配置
  //2. 起始页面：传递参数，触发跳转：this.props.history.push('目标页面的路由?key1=value1&key2=value2')
  //3. 目标页面：获取url中的参数，props.location.search

  fetchList() {
    //通过url获取传递的id参数
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    that.setState({
      loadingList: true
    })
    //查找到id对应的挑战项目
    const challenge = AV.Object.createWithoutData('Challenge', params.id)
    const query = new AV.Query('Creation');
    query.include('publisher')
    query.descending('createdAt')
    //设立查询条件，指查询当前id对应的挑战的所有创意
    query.equalTo('challenge', challenge)
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loadingList: false
      })
    })
  }

  fetchChallenge() {
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    that.setState({
      loadingDetail: true
    })
    const query = new AV.Query('Challenge')
    query.equalTo('objectId', params.id)
    query.find().then(result => {
      if (result.length > 0) {
        that.setState({
          challenge: result[0]
        })
        that.getChallengeJoinerNumber(result[0])
        that.getViewNumber(result[0])
        that.addViewLog(result[0])
      }
    }).finally(() => {
      that.setState({
        loadingDetail: false
      })
    })
  }
  handleCancel = (e) => {
    this.setState({
      visible: false,
      visibleCreation: false,
      visibleSigninModal: false
    });
  }
  toSignin = () => {
    this.setState({
      visibleSigninModal: true
    })
  }
  showModal() {
    if (!AV.User.current()) {
      message.warn('请先登录')
      this.toSignin()
      return
    }
    this.setState({
      visible: true
    })
  }
  reloadHomeList = () => {
    this.fetchList()
  }
  showCreationDetail = (item) => {
    this.setState({
      visibleCreation: true,
      selectCreation: item
    })
  }
  getChallengeJoinerNumber(challenge) {
    const that = this
    const query = new AV.Query('Creation');
    query.equalTo('challenge', challenge)
    query.count().then((count) => {
      that.setState({
        joinerNumber: count
      })
    });
  }
  getViewNumber(challenge) {
    const that = this
    const query = new AV.Query('ViewLog');
    query.equalTo('challenge', challenge)
    query.count().then((count) => {
      that.setState({
        viewNumber: count
      })
    });
  }
  addViewLog(challenge) {
    const viewLog = AV.Object('ViewLog')
    viewLog.set('challenge', challenge)
    viewLog.save()
  }
  render() {
    const { data, challenge, joinerNumber, viewNumber, visible, loadingDetail, loadingList, visibleCreation, selectCreation, visibleSigninModal } = this.state;
    const { isMobile } = this.props;
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ?
          //移动端
          <div className="container-wrap-mobile padding-10">
            <Spin spinning={loadingDetail}>
              <div className="content-wrap-mobile">
                <div className="name text-align-center">{challenge && challenge.attributes.title}</div>
                <img className="picture-mobile" alt="" src={challenge && challenge.attributes.picture.thumbnailURL(50, 50)} />
                <div className="middle-wrap">
                  <div className="number">参与人数：{joinerNumber}</div>
                  <div className="popularity">热度：{viewNumber}</div>
                </div>
                <div className="padding-column-10">{challenge && challenge.attributes.content}</div>
                <VButton title="我要创作" onClick={() => this.showModal()} />
              </div>
            </Spin>
            <Spin spinning={loadingList}>
              <div className="challenge-detail-list">
                <VLabel backgroundClassName="w100" title="作品详情"></VLabel>
                <List
                  className="mt30"
                  grid={{ gutter: 16, column: 1 }}
                  dataSource={data}
                  pagination={{
                    pageSize: 9
                  }}
                  renderItem={item => (
                    <List.Item>
                      <div className="card-wrap-mobile">
                        <img className="card-picture-mobile cursor-pointer" alt="" src={item.attributes.urls[0]} onClick={() => this.showCreationDetail(item)} />
                        <div className="challengedetail-content-wrap">
                          <Paragraph className="challengedetail-content-mobile" ellipsis={{ rows: 3 }}>{item.attributes.content}</Paragraph>
                          <div className="publisher-wrap-mobile">
                            <div className="publisher-info">
                              <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                              <div className="publisher-name">{item.attributes.publisher.attributes.username}</div>
                            </div>
                            <div className="publisher-focus">
                              <VButton className="follow-button" type="ghost" title="关注" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </Spin>
          </div>
          :
          //PC端
          <div className="container-wrap">
            <Spin spinning={loadingDetail}>
              <div className="content-wrap">
                <img className="picture" alt="" src={challenge && challenge.attributes.picture.thumbnailURL(50, 50)} />
                <div className="content">
                  <div className="up-wrap">
                    <div className="name">{challenge && challenge.attributes.title}</div>
                    <VButton title="我要创作" onClick={() => this.showModal()} />
                  </div>
                  <div className="middle-wrap">
                    <div className="number">参与人数：{joinerNumber}</div>
                    <div className="popularity">热度：{viewNumber}</div>
                  </div>
                  <div className="down-wrap">{challenge && challenge.attributes.content}</div>
                </div>
              </div>
            </Spin>
            <Spin spinning={loadingList}>
              <div className="challenge-detail-list">
                <VLabel backgroundClassName="w100" title="作品详情"></VLabel>
                <List
                  className="mt30"
                  grid={{ gutter: 16, column: 3 }}
                  dataSource={data}
                  pagination={{
                    pageSize: 9
                  }}
                  renderItem={item => (
                    <List.Item>
                      <div className="card-wrap">
                        <img className="card-picture cursor-pointer" alt="" src={item.attributes.urls[0]} onClick={() => this.showCreationDetail(item)} />
                        <div className="challengedetail-content-wrap">
                          <Paragraph className="challengedetail-content" ellipsis={{ rows: 3 }}>{item.attributes.content}</Paragraph>
                          <div className="publisher-wrap">
                            <div className="publisher-info">
                              <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                              <div className="publisher-name">{item.attributes.publisher.attributes.username}</div>
                            </div>
                            <div className="publisher-focus">
                              <VButton className="follow-button" type="ghost" title="关注" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </Spin>
          </div>
        }

        <PostPictureModal
          visible={visible}
          onCancel={() => this.handleCancel()}
          reloadHomeList={this.reloadHomeList}
          challenge={challenge}
        ></PostPictureModal>
        <CreationDetailModal
          visible={visibleCreation}
          onCancel={() => this.handleCancel()}
          creation={selectCreation}
        ></CreationDetailModal>
        <SigninModal
          visible={visibleSigninModal}
          onCancel={() => this.handleCancel()}
        ></SigninModal>
      </div>
    )
  }
}

export default HocMedia(ChallengeDetail)