import React from 'react';
import styles from './index.module.css';

export default class VLabel extends React.Component {
  // constructor(props) {
  //   super(props)
  //   console.log({...props})
  // }
  render() {
    const {title, backgroundClassName, titleClassName, fontSizeClass, showLine = true, onClick} = this.props;
    const selectClass = showLine ? styles.title : styles.titleUnSelected
    const tClass = titleClassName ? titleClassName : selectClass
    return (
      <div className={`${styles.background} ${onClick ? styles.cursor : ''} ${backgroundClassName}`} onClick={onClick}>
        { showLine && <div className={styles.line}></div> }
        <div className={`${tClass} ${fontSizeClass}`}>{title}</div>        
      </div>
    )
  }
}