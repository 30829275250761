import React from 'react'
import { List, Avatar, Typography, Spin, message } from 'antd'
import NavigationBar from '../component/NavigationBar'
import VButton from '../component/VButton'
import VLabel from '../component/VLabel'
import moment from 'moment'
import PostDiaryModal from '../component/PostDiaryModal'
import DiaryDetailModal from '../component/DiaryDetailModal'
import SigninModal from '../component/SigninModal'
import { UserOutlined, HeartOutlined, MessageOutlined } from '@ant-design/icons';
import { getQuery } from '../utils/utils';
import HocMedia from '../utils/HocMedia'
const { Paragraph } = Typography
const AV = require('leancloud-storage');
 class TopicDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      data: [],
      loadingDetail: false,
      loadingList: false,
    }
  }
  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchList()
    this.fetchTopic()
  }
  //1. 路由配置
  //2. 起始页面：传递参数，触发跳转：this.props.hidstory.push('目标页面的路由?key1=value1&key2=value2')
  //3. 目标页面：获取url中的参数，props.location.search

  fetchList() {
    //通过url获取传递的id参数
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    that.setState({
      loadingList: true
    })
    //查找到id对应的挑战项目
    const topic = AV.Object.createWithoutData('Topic', params.id)
    const query = new AV.Query('Diary');
    query.include('publisher')
    query.descending('createdAt')
    //设立查询条件，指查询当前id对应的挑战的所有创意
    query.equalTo('topic', topic)
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loadingList: false
      })
    })
  }

  fetchTopic() {
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    that.setState({
      loadingDetail: true
    })
    const query = new AV.Query('Topic')
    query.equalTo('objectId', params.id)
    query.find().then(result => {
      if (result.length > 0) {
        that.setState({
          topic: result[0]
        })
        that.getTopicJoinerNumber(result[0])
        that.getViewNumber(result[0])
        that.addViewLog(result[0])
      }
    }).finally(() => {
      that.setState({
        loadingDetail: false
      })
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      visibleDetail: false,
      visibleSigninModal: false
    });
  }
  toSignin = () => {
    this.setState({
      visibleSigninModal: true
    })
  }
  showModal() {
    if (!AV.User.current()) {
      message.warn('请先登录')
      this.toSignin()
      return
    }
    this.setState({
      visible: true
    })
  }

  reloadHomeList = () => {
    this.fetchList()
  }
  showDiaryDetail = (item) => {
    this.setState({
      visibleDetail: true,
      diary: item
    })
  }
  getTopicJoinerNumber(topic) {
    const that = this
    const query = new AV.Query('Diary');
    query.equalTo('topic', topic)
    query.count().then((count) => {
      that.setState({
        joinerNumber: count
      })
    });
  }
  getViewNumber(topic) {
    const that = this
    const query = new AV.Query('ViewLog');
    query.equalTo('topic', topic)
    query.count().then((count) => {
      that.setState({
        viewNumber: count
      })
    });
  }
  addViewLog(topic) {
    const viewLog = AV.Object('ViewLog')
    viewLog.set('topic', topic)
    viewLog.save()
  }

  render() {
    const { data, topic, joinerNumber, viewNumber, visible, visibleDetail, diary, loadingDetail, loadingList, visibleSigninModal } = this.state;
    const {isMobile}=this.props;
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ? 
        //Mobile
        <div className="topicDetail-container-wrap-mobile padding-10">
          <Spin spinning={loadingDetail} className="mainWidth">
            <div className="topicDetail-content-wrap-mobile">
              <div className="topicDetail-up-wrap-mobile">
                <img className="topicDetail-picture-mobile" alt="" src={topic && topic.attributes.picture.thumbnailURL(50, 50)} />
                <div className="topicDetail-up-right-wrap-mobile">
                  <div className="topicDetail-name-mobile">{topic && topic.attributes.title}</div>
                  <VButton className="button-width" title="参与讨论" onClick={() => this.showModal()} />
                </div>
              </div>
              <div className="topicDetail-content-mobile">
                <div className="topicDetail-middle-wrap-mobile">
                  <div className="number">参与人数：{joinerNumber}</div>
                  <div className="popularity">热度：{viewNumber}</div>
                </div>
                <div className="topicDetail-down-wrap-mobile">{topic && topic.attributes.content}</div>
              </div>
            </div>
          </Spin>
          <Spin spinning={loadingList} className="mainWidth">
            <div className="challenge-detail-list">
              <VLabel backgroundClassName="w100" title="讨论详情"></VLabel>
              <List
                dataSource={data}
                pagination={{
                  pageSize: 9
                }}
                renderItem={item => (
                  <List.Item>
                    <div className="profile-diary-item-wrap">
                      <div className="feeds-publisher-wrap">
                        <div className="feeds-publisher-info">
                          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                          <div className="ml10">
                            <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                            <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                          </div>
                        </div>
                        <div className="publisher-focus">
                          <div className="flex-sb">
                            <HeartOutlined className="fs16 mr20" />
                            <MessageOutlined className="fs16" />
                          </div>
                        </div>
                      </div>
                      <div className="profile-diary-title">{item.attributes.title}</div>
                      <Paragraph className="profile-diary-content" ellipsis={{ rows: 2 }}>{item.attributes.textContent}</Paragraph>
                      <div className="theme-color fs14 cursor-pointer" onClick={() => this.showDiaryDetail(item)}>查看完整内容</div>
                      {/* <div className="challengedetail-content"dangerouslySetInnerHTML={{__html: `${item.attributes.content}`}} /> */}
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Spin>
        </div>
        //宋佳芸做的修改
        : 
        //PC
        <div className="container-wrap">
          <Spin spinning={loadingDetail}>
            <div className="content-wrap">
              <img className="picture" alt="" src={topic && topic.attributes.picture.thumbnailURL(50, 50)} />
              <div className="content">
                <div className="up-wrap">
                  <div className="name">{topic && topic.attributes.title}</div>
                  <VButton title="参与讨论" onClick={() => this.showModal()} />
                </div>
                <div className="middle-wrap">
                  <div className="number">参与人数：{joinerNumber}</div>
                  <div className="popularity">热度：{viewNumber}</div>
                </div>
                <div className="down-wrap">{topic && topic.attributes.content}</div>
              </div>
            </div>
          </Spin>
          <Spin spinning={loadingList}>
            <div className="challenge-detail-list">
              <VLabel backgroundClassName="w100" title="讨论详情"></VLabel>
              <List
                dataSource={data}
                pagination={{
                  pageSize: 9
                }}
                renderItem={item => (
                  <List.Item>
                    <div className="profile-diary-item-wrap">
                      <div className="feeds-publisher-wrap">
                        <div className="feeds-publisher-info">
                          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                          <div className="ml10">
                            <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                            <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                          </div>
                        </div>
                        <div className="publisher-focus">
                          <div className="flex-sb">
                            <HeartOutlined className="fs16 mr20" />
                            <MessageOutlined className="fs16" />
                          </div>
                        </div>
                      </div>
                      <div className="profile-diary-title">{item.attributes.title}</div>
                      <Paragraph className="profile-diary-content" ellipsis={{ rows: 2 }}>{item.attributes.textContent}</Paragraph>
                      <div className="theme-color fs14 cursor-pointer" onClick={() => this.showDiaryDetail(item)}>查看完整内容</div>
                      {/* <div className="challengedetail-content"dangerouslySetInnerHTML={{__html: `${item.attributes.content}`}} /> */}
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Spin>
        </div>
        }
        
        <PostDiaryModal
          visible={visible}
          onCancel={() => this.handleCancel()}
          reloadHomeList={this.reloadHomeList}
          topic={topic}
        ></PostDiaryModal>
        <DiaryDetailModal
          visible={visibleDetail}
          onCancel={() => this.handleCancel()}
          diary={diary}
        ></DiaryDetailModal>
        <SigninModal
          visible={visibleSigninModal}
          onCancel={() => this.handleCancel()}
        ></SigninModal>
      </div>
    )
  }
}

export default HocMedia(TopicDetail);