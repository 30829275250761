import React from 'react';
import { Modal, Input, Upload, message, Popover } from 'antd';
import VButton from '../VButton'
import { PlusOutlined, EnvironmentOutlined, TagOutlined } from '@ant-design/icons';
import styles from './index.module.css';
import { getBase64, TagMap } from '../../utils/utils'
const AV = require('leancloud-storage')

export default class PostPictureModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      uploading: false,
      fileList: [],
      hovered: false,
      hoveredLocation: false,
    };
  }

  componentDidUpdate(nextProps) {
    //加载草稿内容
    if (this.props.visible === true && nextProps.visible === false && this.props.draftCreation && this.props.isDraft) {
      this.setCreation()
    }
  }

  setCreation() {
    const draftCreation = this.props.draftCreation.attributes
    const fileList = draftCreation.urls.map((item, index) => {
      return {
        uid: index,
        url: item,
      }
    })
    this.setState({
      title: draftCreation.title,
      content: draftCreation.content,
      fileList,
      location: draftCreation.location,
      type: draftCreation.type,
    })
  }

  handleHoverChange = (visible) => {
    this.setState({
      hovered: visible
    })
  }
  handleHoverLocationChange = (visible) => {
    this.setState({
      hoveredLocation: visible
    })
  }
  changeTitle(e) {
    this.setState({
      title: e.currentTarget.value
    })
  }
  changeContent(e) {
    this.setState({
      content: e.currentTarget.value
    })
  }
  changeLocation(e) {
    this.setState({
      location: e.currentTarget.value
    })
  }
  selectType = (type) => {
    this.setState({
      type: TagMap[type].value
    })
  }
  uploadFile = async (file) => {
    const that = this
    const fileStr = await getBase64(file)
    const data = { base64: fileStr };
    const avfile = new AV.File(file.name, data)
    this.setState({
      uploading: true
    })
    message.loading('图片上传中', avfile.id)
    avfile.save().then(res => {
      message.success('上传成功', avfile.id)
      that.setState(state => ({
        fileList: [
          ...state.fileList,
          {
            uid: res.id,
            url: res.thumbnailURL(86, 86),
            name: res.name,
            status: 'done'
          }],
      }));
    }, (error) => {
      console.log(error)
      // message.error(error)
      // 保存失败，可能是文件无法被读取，或者上传过程中出现问题
    }).finally(() => {
      that.setState({
        uploading: false
      })
    })
    return false;
  }
  post() {
    const { onCancel, reloadHomeList, challenge, isDraft, draftCreation } = this.props
    const { fileList, title, content, location, type, uploading } = this.state
    const that = this
    if (!fileList.length) {
      message.warn('请上传作品')
      return
    }
    if (uploading) {
      message.warn('请等待图片上传完成')
      return
    }
    if (!type) {
      message.warn('请选择分类标签')
      return
    }
    const urls = fileList.map(i => {
      return i.url
    })
    const creation = new AV.Object('Creation')
    creation.set('urls', urls)
    creation.set('publisher', AV.User.current())
    if (title) {
      creation.set('title', title)
    }
    if (content) {
      creation.set('content', content)
    }
    if (location) {
      creation.set('location', location)
    }
    if (type) {
      creation.set('type', type)
    }
    if (challenge) {
      creation.set('challenge', challenge)
    }
    creation.save().then(res => {
      message.success('发送成功')
      this.setState({
        title: undefined,
        content: undefined,
        fileList: [],
        location: undefined,
        type: undefined,
      })
      reloadHomeList()
      if (isDraft) {
        that.updateDraft(draftCreation)
      } 
    }).finally(() => {
      onCancel()
    })
  }
  updateDraft(draftCreation) {
    const draft = AV.Object.createWithoutData('Draft', draftCreation.id)
    draft.set('published', true)
    draft.save()
  }
  saveDraft() {
    const { onCancel } = this.props
    const { fileList, title, content, location, type } = this.state
    if (!fileList.length) {
      message.warn('上传作品')
      return
    }
    const urls = fileList.map(i => {
      return i.url
    })
    const draft = new AV.Object('Draft')
    draft.set('urls', urls)
    draft.set('type', type)
    draft.set('category', 'creation')
    draft.set('publisher', AV.User.current())
    if (title) {
      draft.set('title', title)
    }
    if (content) {
      draft.set('content', content)
    }
    if (location) {
      draft.set('location', location)
    }
    if (type) {
      draft.set('type', type)
    }
    draft.save().then(res => {
      message.success('草稿已保存')
    }).finally(() => {
      onCancel()
    })
  }
  render() {
    const { visible, onCancel, isDraft } = this.props
    const { fileList, hoveredLocation, hovered, location, type, title, content } = this.state
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>上传作品</div>
      </div>
    );
    const uploadProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => this.uploadFile(file),
      fileList,
    };
    return (
      <div className={styles.container}>
        <Modal
          style={{ width: 500 }}
          title="发创作"
          visible={visible}
          onCancel={onCancel}
          maskClosable={false}
          footer={null}
          destroyOnClose={true}
        >
          <div className={styles.content}>
            <Input className={styles.input} bordered={false} placeholder='输入作品名称（可为空）'
              onChange={(e) => this.changeTitle(e)} value={isDraft ? title : undefined}></Input>
            <div className={styles.line}></div>
            <Input.TextArea className={styles.area} row={6} bordered={false} placeholder='输入作品故事（可为空）'
              onChange={(e) => this.changeContent(e)} value={isDraft ? content : undefined}></Input.TextArea>
            <Upload
              className={styles.upload}
              key='index'
              listType="picture-card"
              {...uploadProps}
            // onPreview={(e) => this.handlePreview(e)}
            // showUploadList={{ showRemoveIcon: false }}
            >
              {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            <div className={styles.options}>
              <Popover
                content={
                  <div className={styles.options}>
                    <Input className={styles.input} bordered={false} placeholder='输入地点'
                      onChange={(e) => this.changeLocation(e)} value={location}></Input>
                  </div>
                }
                trigger="hover"
                visible={hoveredLocation}
                onVisibleChange={this.handleHoverLocationChange}
              >
                <div className={styles.iconTextWrap}>
                  <EnvironmentOutlined className={styles.icon} />
                  <div className={styles.iconText}>{location || '你在哪里'}</div>
                </div>
              </Popover>

              <Popover
                content={
                  <div>
                    {
                      Object.values(TagMap).map(i => {
                        return <div key={i.value} className={styles.popoverItem} onClick={() => this.selectType(i.value)}>{i.text}</div>
                      })
                    }
                  </div>
                }
                trigger="hover"
                visible={hovered}
                onVisibleChange={this.handleHoverChange}
              >
                <div className={styles.iconTextWrap}>
                  <TagOutlined className={styles.icon} />
                  <div className={styles.iconText}>{(TagMap[type] && TagMap[type].text) || '添加分类标签'}</div>
                </div>
              </Popover>
            </div>
            <div className={styles.footer}>
              <VButton title="取消" backClass={styles.cancel} titleClass={styles.cancelTitle} onClick={onCancel} />
              <div className={styles.footerRight}>
                <VButton title="保存" type="ghost" backClass={styles.post} titleClass={styles.postTitle} onClick={() => this.saveDraft()} />
                <VButton title="发送" backClass={styles.save} onClick={() => this.post()} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

// export default class Index extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = {
//       draftCreation: props.draftCreation
//     }
//   }
//   componentDidUpdate(nextProps) {    
//     console.log('[This]', this.props)
//     console.log('[Next]', nextProps)
//     //加载草稿内容
//     if (this.props.visible === true && nextProps.visible === false && this.props.draftCreation && this.props.isDraft) {
//       this.setState({
//         draftCreation: this.props.draftCreation
//       })
//     }
//   }  
//   render() {
//     const { visible, onCancel } = this.props;
//     const {draftCreation} = this.state
//     return (
//       <Modal
//           // style={{ width: 500 }}
//           title="发创作"
//           visible={visible}
//           onCancel={onCancel}
//           footer={null}
//           destroyOnClose={true}
//         >
//         <Content {...this.props} draftCreation={draftCreation} />
//       </Modal>
//     );
//   }
// }