import React from 'react'
import VButton from '../../component/VButton'
import { List, Spin } from 'antd'
import defaultAvatar from '../../resources/avatar.png';
import { getQuery } from '../../utils/utils';
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');

class ProfileFollow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false
    }
  }
  componentDidMount() {
    this.fetchFollowee()
  }
  fetchFollowee() {
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    const publisher = params.id ? AV.Object.createWithoutData('_User', params.id) : AV.User.current()
    this.setState({
      loading: true
    })
    var query = new AV.Query('Followers');
    query.equalTo('fromUser', publisher)
    query.include('toUser');
    query.find().then(function (followees) {
      that.setState({
        data: followees
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    });
  }
  navigateToProfile(item) {
    this.props.history.push(`/profileother?id=${item.attributes.toUser.id}`)
  }
  render() {
    const { data, loading } = this.state
    const { isMobile } = this.props
    return (
      <div>
        <Spin spinning={loading}>
          <List
            className={isMobile ? "" : "mt30"}
            dataSource={data}
            pagination={{
              pageSize: 9
            }}
            renderItem={item => (
              <List.Item className="cursor-pointer" onClick={()=>this.navigateToProfile(item)}>
                <div className={isMobile ? "follower-wrap-mobile" : "follower-wrap"}>
                  <div className="profile-left">
                    <div>
                      <img className="follower-avatar" src={item.attributes.toUser.attributes.avatar ? item.attributes.toUser.attributes.avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                    </div>
                    <div className="profile-info-wrap">
                      <div className="flex-sb-center">
                        <div className="profile-name">{item.attributes.toUser.attributes.username}</div>
                      </div>
                      <div className="profile-desc">{item.attributes.toUser.attributes.brief || '暂无'}</div>
                    </div>
                  </div>
                  <div className="profile-post" >
                    <VButton type="ghost" size={isMobile ? "small" : "large"} title="关注" />
                  </div>
                </div>
              </List.Item>
            )}
          />
        </Spin>
      </div>
    )
  }
}

export default HocMedia(ProfileFollow)