import React from 'react'
import moment from 'moment'
import VButton from '../../component/VButton'
import { Avatar, Modal, List, Input, message } from 'antd'
import { TagMap } from '../../utils/utils'
import { UserOutlined, HeartOutlined, MessageOutlined, MoreOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');
class Content extends React.Component {
  constructor(props) {
    super(props)
    // console.log('props', props.visible)
    this.state = {
      comments: []
    }
  }
  componentDidMount() {
    this.fetchComments()
  }
  changeComment(e) {
    this.setState({
      comment: e.currentTarget.value
    })
  }
  postComment = () => {
    const { creation, toSignin } = this.props
    const { comment } = this.state
    const that = this
    if (!AV.User.current()) {
      message.warn('请先登录')
      if (toSignin) {
        toSignin()
      }
      return
    }
    if (AV.User.current().attributes.status) {
      message.warn('该账户涉及违规无法评论，如有疑问请联系管理员')
      return
    }
    if (!comment) {
      message.warn('请输入评论内容')
      return
    }
    const com = AV.Object("Comment")
    com.set('content', comment)
    com.set('sender', AV.User.current())
    // com.set('receiver', )
    com.set('targetCreation', creation)
    com.save().then(res => {
      message.success('评论成功')
      that.fetchComments()
      that.setState({
        comment: undefined
      })
    })
  }
  fetchComments() {
    const { creation } = this.props
    if (!creation) {
      return
    }
    const that = this
    const queryComment = new AV.Query('Comment')
    queryComment.equalTo('targetCreation', creation)
    queryComment.include('sender')
    queryComment.descending('createdAt')
    queryComment.find().then(res => {
      that.setState({
        comments: res
      })
    })
  }
  render() {
    const { creation = {}, isMobile } = this.props
    const createdAt = moment(creation.createdAt).format('YYYY-MM-DD HH:mm')
    const data = (creation.attributes && creation.attributes.urls) || []
    const { title, content, type } = creation.attributes || {}
    const { username, avatar } = (creation.attributes && creation.attributes.publisher.attributes) || {}
    const { comments, comment } = this.state
    return (
      <>
        {isMobile ?
          //Mobile
          <div className="detail-modal-mobile">
            <div className="creation-image-wrap-mobile">
              <List
                dataSource={data}
                pagination={{
                  pageSize: 1
                }}
                renderItem={item => (
                  <List.Item>
                    <img className="detail-picture-mobile" alt="" src={item} />
                  </List.Item>
                )}
              />
            </div>
            <div className="detail-wrap-mobile">
              <div className="post-wrap-mobile">
                <div className="post-publisher-wrap">
                  <div className="publisher-info">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                    <div className="post-publisher-info">
                      <div className="publisher-name">{username}</div>
                      <div className="post-publisher-time">{createdAt}</div>
                    </div>
                  </div>
                  <div className="publisher-focus">
                    <VButton className="follow-button" type="ghost" title="关注" />
                  </div>
                </div>
                <div className="fs16 mt10">{title}</div>
                <div className="fs14 mt10">{content}</div>
                {type && <div className="feeds-tag">#{TagMap[type].text}</div>}
                <div className="flex-sb mt10">
                  <div className="flex-sb">
                    <HeartOutlined className="fs16 mr20" />
                    <MessageOutlined className="fs16" />
                  </div>
                  <MoreOutlined className="fs16" />
                </div>
              </div>
              <div className="comment-wrap-mobile">
                {/* 评论 */}
                <List
                  className="feeds-comments"
                  dataSource={comments}
                  renderItem={item => (
                    <List.Item>
                      <div className="feeds-comments-wrap">
                        <div className="feeds-comment-content-wrap">
                          <div className="feeds-publisher-wrap">
                            <div className="feeds-publisher-info">
                              <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                              <div className="ml10">
                                <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                              </div>
                            </div>
                            <div className="publisher-focus">
                              {/* <VButton className="follow-button" type="ghost" title="关注" /> */}
                            </div>
                          </div>
                          <div className="feeds-content">{item.attributes.content}</div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
              <div className="comment-post-wrap-mobile">
                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                <div className="comment-input-mobile">
                  <Input value={comment} placeholder="写评论..." bordered={false} onChange={(e) => this.changeComment(e)}></Input>
                  <VButton title="发送" backClass={styles.send} titleClass={styles.sendTitle} onClick={this.postComment} />
                </div>
              </div>
            </div>
          </div>
          :
          //PC
          <div className="detail-modal">
            <div className="creation-image-wrap">
              <List
                dataSource={data}
                pagination={{
                  pageSize: 1
                }}
                renderItem={item => (
                  <List.Item>
                    <img className="detail-picture" alt="" src={item} />
                  </List.Item>
                )}
              />
            </div>
            <div className="detail-wrap">
              <div className="post-wrap">
                <div className="post-publisher-wrap">
                  <div className="publisher-info">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                    <div className="post-publisher-info">
                      <div className="publisher-name">{username}</div>
                      <div className="post-publisher-time">{createdAt}</div>
                    </div>
                  </div>
                  <div className="publisher-focus">
                    <VButton className="follow-button" type="ghost" title="关注" />
                  </div>
                </div>
                <div className="fs16 mt10">{title}</div>
                <div className="fs14 mt10">{content}</div>
                {type && <div className="feeds-tag">#{TagMap[type].text}</div>}
                <div className="flex-sb mt10">
                  <div className="flex-sb">
                    <HeartOutlined className="fs16 mr20" />
                    <MessageOutlined className="fs16" />
                  </div>
                  <MoreOutlined className="fs16" />
                </div>
              </div>
              <div className="comment-wrap">
                {/* 评论 */}
                <List
                  className="feeds-comments"
                  dataSource={comments}
                  renderItem={item => (
                    <List.Item>
                      <div className="feeds-comments-wrap">
                        <div className="feeds-comment-content-wrap">
                          <div className="feeds-publisher-wrap">
                            <div className="feeds-publisher-info">
                              <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                              <div className="ml10">
                                <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                              </div>
                            </div>
                            <div className="publisher-focus">
                              {/* <VButton className="follow-button" type="ghost" title="关注" /> */}
                            </div>
                          </div>
                          <div className="feeds-content">{item.attributes.content}</div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
              <div className="comment-post-wrap">
                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                <div className="comment-input">
                  <Input value={comment} placeholder="写评论..." bordered={false} onChange={(e) => this.changeComment(e)}></Input>
                  <VButton title="发送" backClass={styles.send} titleClass={styles.sendTitle} onClick={this.postComment} />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

class Index extends React.Component {
  render() {
    const { visible, onCancel, isMobile } = this.props;
    return (
      <>
        {isMobile ?
          //Mobile
          <Modal
            style={{ width: "100%" }}
            // title="创作详情"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            destroyOnClose={true}
          >
            <Content {...this.props} />
          </Modal>
          :
          //PC
          <Modal
            style={{ minWidth: 940, maxHeight: 600 }}
            // title="创作详情"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            destroyOnClose={true}
          >
            <Content {...this.props} />
          </Modal>
        }
      </>
    );
  }
}

export default HocMedia(Index)