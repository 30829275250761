import React from 'react'
import NavigationBar from '../component/NavigationBar'
import VMenu from '../component/VMenu'
import VLabel from '../component/VLabel'
import { List, Spin, Typography } from 'antd'
import moment from 'moment'
const AV = require('leancloud-storage');
const Paragraph = Typography.Paragraph
export default class MessageList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 0,
      globalData: [],
      data: [],
      loading: false
    }
  }

  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchGlobalMessages()
    this.fetchMessages()
  }

  fetchGlobalMessages() {
    if (!AV.User.current()) {
      return
    }
    const that = this
    that.setState({
      loading: true
    })
    const query = new AV.Query('Messages');
    query.addDescending('createdAt')
    query.equalTo('rcvgroup', 0)
    query.find().then(result => {
      that.setState({
        globalData: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }
  fetchMessages() {
    if (!AV.User.current()) {
      return
    }
    const that = this
    that.setState({
      loading: true
    })
    console.log(AV.User.current().id)
    const query = new AV.Query('Messages');
    query.addDescending('createdAt')
    query.equalTo('rcvgroup', 1)
    query.contains('receiver', AV.User.current().id)
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }
  handleMenuClick(key) {
    this.setState({
      current: key
    })
  }
  getRender(current) {
    const { globalData, data, loading } = this.state
    if (current === 0) {
      return <Spin spinning={loading}>
        <div className="mainWidth">
          <List
            // grid={{ gutter: 16, column: 3 }}
            dataSource={globalData}
            pagination={{
              pageSize: 9
            }}
            renderItem={item => (
              <List.Item>
                <div className="profile-diary-item-wrap">
                  <div className="feeds-publisher-wrap">
                    <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                  </div>
                  <div className="profile-diary-title">{item.attributes.title}</div>
                  <Paragraph className="profile-diary-content" ellipsis={{ rows: 4, expandable: true, symbol: '展开' }}>{item.attributes.content}</Paragraph>
                </div>
              </List.Item>
            )}
          />
        </div>
      </Spin>
    } else {
      return <Spin spinning={loading}>
        <div className="mainWidth">
          <List
            dataSource={data}
            pagination={{
              pageSize: 9
            }}
            renderItem={item => (
              <List.Item>
                <div className="profile-diary-item-wrap">
                  <div className="feeds-publisher-wrap">
                    <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                  </div>
                  <div className="profile-diary-title">{item.attributes.title}</div>
                  <Paragraph className="profile-diary-content" ellipsis={{ rows: 4, expandable: true, symbol: '展开' }}>{item.attributes.content}</Paragraph>
                </div>
              </List.Item>
            )}
          />
        </div>
      </Spin>
    }
  }
  render() {
    const { current } = this.state;
    return (
        <div>
          <NavigationBar history={this.props.history} />
          <div className="container-wrap-mobile">
            <div className="profile-menu-container-wrap mt20  padding-row-10">
              <VMenu className="profile-menu">
                <VLabel title="公告" backgroundClassName="mr50" showLine={current === 0} onClick={() => this.handleMenuClick(0)}></VLabel>
                <VLabel title="私信" backgroundClassName="mr50" showLine={current === 1} onClick={() => this.handleMenuClick(1)}></VLabel>
              </VMenu>
              {
                this.getRender(current)
              }
            </div>
          </div>
        </div>
    )
  }
}