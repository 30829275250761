import React from 'react'
import { Typography, Avatar, List, Spin } from 'antd'
import { UserOutlined, HeartOutlined, MessageOutlined } from '@ant-design/icons';
import { getQuery } from '../../utils/utils';
import DiaryDetailModal from '../../component/DiaryDetailModal'
import moment from 'moment';
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');
const { Paragraph } = Typography
class ProfileDiary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      visible: false,
      loading: false
    }
  }
  componentDidMount() {
    this.fetchList()
  }
  //1. 路由配置
  //2. 起始页面：传递参数，触发跳转：this.props.history.push('目标页面的路由?key1=value1&key2=value2')
  //3. 目标页面：获取url中的参数，props.location.search

  fetchList() {
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    const publisher = params.id ? AV.Object.createWithoutData('_User', params.id) : AV.User.current()
    this.setState({
      loading: true
    })
    const query = new AV.Query('Diary');
    query.equalTo('publisher', publisher)
    query.include('publisher')
    query.descending('createdAt')
    //设立查询条件，指查询当前id对应的挑战的所有创意
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }
  handleOk = e => {
    this.setState({
      visible: false,
    });
  }
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }
  showDiaryDetail = (item) => {
    this.setState({
      visible: true,
      diary: item
    })
  }
  render() {
    const { data, visible, diary, loading } = this.state
    const { isMobile } = this.props
    return (
      <div>
        <Spin spinning={loading}>
          <List
            className={isMobile ? "" : "mt30"}
            dataSource={data}
            pagination={{
              pageSize: 9
            }}
            renderItem={item => (
              <List.Item>
                <div className="profile-diary-item-wrap">
                  <div className="feeds-publisher-wrap">
                    <div className="feeds-publisher-info">
                      <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                      <div className="ml10">
                        <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                        <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                      </div>
                    </div>
                    <div className="publisher-focus">
                      <div className="flex-sb">
                        <HeartOutlined className="fs16 mr20" />
                        <MessageOutlined className="fs16" />
                      </div>
                    </div>
                  </div>
                  <div className="profile-diary-title">{item.attributes.title}</div>
                  <Paragraph className="profile-diary-content" ellipsis={{ rows: 2 }}>{item.attributes.textContent}</Paragraph>
                  <div className="theme-color fs14 cursor-pointer" onClick={() => this.showDiaryDetail(item)}>查看完整内容</div>
                  {/* <div className="challengedetail-content"dangerouslySetInnerHTML={{__html: `${item.attributes.content}`}} /> */}
                </div>
              </List.Item>
            )}
          />
        </Spin>
        <DiaryDetailModal
          visible={visible}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          diary={diary}
        ></DiaryDetailModal>
      </div>
    )
  }
}

export default HocMedia(ProfileDiary)