export const getQuery = str => {
  return str
    .replace('?', '')
    .split('&')
    .reduce((r, i) => {
      const [key, value] = i.split('=');
      return { ...r, [key]: value };
    }, {});
}
// export const getBase64 = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }
export const getBase64 = (file) => {
  var fileSize = parseFloat(parseInt(file['size']) / 1024 / 1024).toFixed(2);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      // 创建一个img对象
      var img = new Image()
      // let base64Str = reader.result.split[','][1]
      img.src = e.target.result
      // base64地址图片加载完毕后执行
      img.onload = function () {
        //默认按比例压缩   
        var w = this.width,
          h = this.height;
        //生成canvas   
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        var base64;
        // 创建属性节点   
        canvas.setAttribute("width", w);
        canvas.setAttribute("height", h);
        ctx.drawImage(this, 0, 0, w, h);
        if (fileSize < 1) {
          //如果图片小于一兆 那么不执行压缩操作
          base64 = canvas.toDataURL(file['type'], 1);
        } else if (fileSize > 1 && fileSize < 2) {
          //如果图片大于1M并且小于2M 那么压缩0.5
          base64 = canvas.toDataURL(file['type'], 0.5);
        } else {
          //如果图片超过2m 那么压缩0.2
          base64 = canvas.toDataURL(file['type'], 0.2);
        }
        // var newUrl = canvas.toDataURL('image/jpeg', 0.5)
        resolve(base64);
      }
    }
    reader.onerror = error => reject(error);
  });
}
export const TagMap = {
  emotion: {
    text: '情感',
    value: 'emotion'
  },
  grow: {
    text: '成长',
    value: 'grow'
  },
  people: {
    text: '人文',
    value: 'people'
  },
  life: {
    text: '生活',
    value: 'life'
  },
  creative: {
    text: '创意',
    value: 'creative'
  },
}
export const ReportMap = {
  SheHuangXinXi: {
    text: '涉黄信息',
    value: 'SheHuangXinXi'
  },
  RenShenGongJi: {
    text: '人身攻击',
    value: 'RenShenGongJi'
  },
  LaJiYingXiao: {
    text: '垃圾营销',
    value: 'LaJiYingXiao'
  },
  NeiRongChaoXi: {
    text: '内容抄袭',
    value: 'NeiRongChaoXi'
  },
  ZhaPianXinXi: {
    text: '诈骗信息',
    value: 'ZhaPianXinXi'
  },
  WeiFaXinXi: {
    text: '违法信息',
    value: 'WeiFaXinXi'
  }
}
export const GenderMap = {
  0: {
    text: '保密',
    value: 0
  },
  1: {
    text: '男',
    value: 1
  },
  2: {
    text: '女',
    value: 2
  }
}
export const isValidPwd = (pwd) => {
  //return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(pwd)
  return new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/).test(pwd)
}
export const isEmail = (email) => {
  var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
  return reg.test(email);
}
export default {
  getQuery,
  getBase64,
  TagMap,
  GenderMap,
  ReportMap,
  isValidPwd,
  isEmail
}
