import React from 'react'
import moment from 'moment'
import NavigationBar from '../../component/NavigationBar'
import VButton from '../../component/VButton'
import VLabel from '../../component/VLabel'
import VMenu from '../../component/VMenu'
import { message, Spin } from 'antd'
import { getQuery, GenderMap } from '../../utils/utils';
import ProfileCreation from './ProfileCreation'
import ProfileDiary from './ProfileDiary'
import ProfileFollow from './ProfileFollow'
import ProfileFollower from './ProfileFollower'
import defaultAvatar from '../../resources/avatar.png';
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');

class ProfileOther extends React.Component {
  constructor(props) {
    super(props)
    const search = this.props.location.search
    const params = getQuery(search)
    this.state = {
      current: 'creation',
      userInfo: {},
      id: params.id,
      loadingProfile: false
    }
  }
  componentDidMount() {
    this.fetchProfile()
  }
  componentWillReceiveProps(nextProps) {
    const search = nextProps.location.search
    const params = getQuery(search)    
    if (this.state.id !== params.id) {
      // 在这里进行异步操作或者更新状态
      this.setState({
        id: params.id,
        current: 'creation'
      }, () => {
        this.fetchProfile();
      });
      
    }
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const search = nextProps.location.search
  //   const params = getQuery(search)
  //   const { id } = params;
  //   console.log('【nextProps】', id)
  //   console.log('【prevState】', prevState.id)
  //   // 当传入的type发生变化的时候，更新state
  //   if (id !== prevState.id) {
  //     return {
  //       id,
  //     };
  //   }
  //   // 否则，对于state不进行任何操作
  //   return null;
  // }
  fetchProfile() {
    const { id } = this.state
    const that = this
    this.setState({
      loadingProfile: true
    })
    const userQuery = new AV.Query('_User');
    userQuery.equalTo('objectId', id)
    userQuery.first().then(user => {
      console.log(moment().diff(user.attributes.birthday, 'year'))
      that.setState({
        userInfo: {
          ...user.attributes,
          age: `${moment().diff(user.attributes.birthday, 'year')}岁`
        }
      })
    }).finally(() => {
      that.setState({
        loadingProfile: false
      })
    })
  }
  handleClick(e) {
    this.setState({
      current: e.key
    })
  }
  getRender(current) {
    if (current === 'creation') {
      return <ProfileCreation {...this.props} />
    } else if (current === 'diary') {
      return <ProfileDiary {...this.props} />
    } else if (current === 'follow') {
      return <ProfileFollow {...this.props} />
    } else {
      return <ProfileFollower {...this.props} />
    }
  }
  handleMenuClick(key) {
    this.setState({
      current: key
    })
  }
  navigateToEditProfile = () => {
    this.props.history.push('/profileedit')
  }
  handleFollow = () => {
    const { id } = this.state
    if (!AV.User.current()) {
      this.toSignin()
      return
    }
    const user = AV.Object.createWithoutData('_User', id)
    const follow = new AV.Object('Followers')
    follow.set('toUser', user)
    follow.set('fromUser', AV.User.current())
    follow.save().then(res => {
      message.success('已关注')
    })
  }
  render() {
    const { current, userInfo, loadingProfile } = this.state
    const { username, gender = 0, avatar, location, brief, age } = userInfo
    const {isMobile} = this.props
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ?
          //Mobile
          <>
            <Spin spinning={loadingProfile}>
              <div className="container-wrap padding-10">
                <div className="profile-wrap-mobile">
                  <div className="profile-left">
                    <div className="flex-sb-column">
                      <img className={isMobile ? "profile-avatar-mobile" : "profile-avatar"} src={avatar ? avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                      <VButton title="关注" onClick={this.handleFollow} />
                    </div>
                    <div className="profile-info-wrap">
                      <div className="flex-sb-center">
                        <div className="profile-name">{username}</div>
                      </div>
                      <div className="profile-age-wrap">
                        <div className="profile-age">{age}</div>
                        <div className="profile-age">{GenderMap[gender].text}</div>
                        <div className="profile-age">{location}</div>
                      </div>
                      <div className="profile-desc">{brief}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Spin>
            <div className="profile-menu-container-wrap padding-10">
              <VMenu className="profile-menu flex-sb">
                <VLabel fontSizeClass="fs16" title="我的创作" showLine={current === 'creation'} onClick={() => this.handleMenuClick('creation')}></VLabel>
                <VLabel fontSizeClass="fs16" title="我的日记" showLine={current === 'diary'} onClick={() => this.handleMenuClick('diary')}></VLabel>
                <VLabel fontSizeClass="fs16" title="我的关注" showLine={current === 'follow'} onClick={() => this.handleMenuClick('follow')}></VLabel>
                <VLabel fontSizeClass="fs16" title="关注我的" showLine={current === 'follower'} onClick={() => this.handleMenuClick('follower')}></VLabel>
              </VMenu>
              {
                this.getRender(current)
              }
            </div>
          </>
          :
          //PC
          <>
            <Spin spinning={loadingProfile}>
              <div className="container-wrap">
                <div className="profile-wrap">
                  <div className="profile-left">
                    <div>
                      <img className="profile-avatar" src={avatar ? avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                    </div>
                    <div className="profile-info-wrap">
                      <div className="flex-sb-center">
                        <div className="profile-name">{username}</div>
                      </div>
                      <div className="profile-age-wrap">
                        <div className="profile-age">{age}</div>
                        <div className="profile-age">{GenderMap[gender].text}</div>
                        <div className="profile-age">{location}</div>
                      </div>
                      <div className="profile-desc">{brief}</div>
                    </div>
                  </div>
                  <div className="profile-post" >
                  <VButton title="关注" onClick={this.handleFollow} />
                  </div>
                </div>
              </div>
            </Spin>
            <div className="profile-menu-container-wrap">
              <VMenu className="profile-menu">
                <VLabel title="我的创作" backgroundClassName="mr50" showLine={current === 'creation'} onClick={() => this.handleMenuClick('creation')}></VLabel>
                <VLabel title="我的日记" backgroundClassName="mr50" showLine={current === 'diary'} onClick={() => this.handleMenuClick('diary')}></VLabel>
                <VLabel title="我的关注" backgroundClassName="mr50" showLine={current === 'follow'} onClick={() => this.handleMenuClick('follow')}></VLabel>
                <VLabel title="关注我的" showLine={current === 'follower'} onClick={() => this.handleMenuClick('follower')}></VLabel>
              </VMenu>
              {
                this.getRender(current)
              }
            </div>
          </>
        }
      </div>
    )
  }
}

export default HocMedia(ProfileOther)