import React from 'react';
import styles from './index.module.css';

export default class VButton extends React.Component {
  // constructor(props) {
  //   super(props)
  //   console.log({...props})
  // }
  render() {
    const {title, onClick, type, size, backClass, titleClass, disabled} = this.props;
    return (
      <div className={`${type === 'ghost' ? styles.backgroundBorder : styles.background} ${type === 'ghost' && size === 'large' ? styles.largeBackgroundBorder : ''} ${backClass}`} onClick={disabled ? null : onClick}>
        <div className={`${type === 'ghost' ? styles.titleBorder : styles.title} ${type === 'ghost' && size === 'large' ? styles.largeTitleBorder : ''} ${titleClass}`}>{title}</div>
      </div>
    )
  }
}