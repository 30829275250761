import React from 'react';
import './App.css';
import Home from './pages/Home.js';
import Official from './pages/Official.js';
import ChallengeList from './pages/ChallengeList.js';
import ChallengeDetail from './pages/ChallengeDetail.js';
import MuseumList from './pages/MuseumList.js';
import MuseumDetail from './pages/MuseumDetail.js';
import TopicList from './pages/TopicList.js';
import TopicDetail from './pages/TopicDetail.js';
import Profile from './pages/Profile/Profile.js';
import ProfileOther from './pages/Profile/ProfileOther.js';
import ProfileEdit from './pages/Profile/ProfileEdit.js';
import MessageList from './pages/MessageList.js';
import Support from './pages/Support.js';
import {
  HashRouter as Router,
  Route
} from 'react-router-dom';
const AV = require('leancloud-storage');
//沙盒地址
// AV.init({
//   appId: "o9e72seHaD7l6CKjLkJWe8Jf-MdYXbMMI",
//   appKey: "g526faLlReOX4bFVNvog17U9"
// });
//生产地址
AV.init({
  appId: "kYtddmLAUObYAasnXs9Dyyom-MdYXbMMI",
  appKey: "E2G4Qw9UYDcKLgv5wKIDFVaj"
});

function App() {
  return (
    <div className="App">
      <Router>
        <Route exact path="/" component={Home}></Route>
        <Route exact path="/official" component={Official}></Route>
        <Route exact path="/challenges" component={ChallengeList}></Route>
        <Route exact path="/challengedetail" component={ChallengeDetail}></Route>
        <Route exact path="/topics" component={TopicList}></Route>
        <Route exact path="/topicdetail" component={TopicDetail}></Route>
        <Route exact path="/profileother" component={ProfileOther}></Route>
        <Route exact path="/profile" component={Profile}></Route>
        <Route exact path="/profileedit" component={ProfileEdit}></Route>
        <Route exact path="/museum" component={MuseumList}></Route>
        <Route exact path="/museumdetail" component={MuseumDetail}></Route>
        <Route exact path="/messages" component={MessageList}></Route>
        <Route exact path="/support" component={Support}></Route>
      </Router>
    </div>
  );
}

export default App;
