import React from 'react'
import VButton from '../../component/VButton'
import { List, Avatar, Spin, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { getQuery } from '../../utils/utils';
import CreationDetailModal from '../../component/CreationDetailModal'
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');
const { Paragraph } = Typography


class ProfileCreation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      data: [],
      loading: false
    }
  }
  componentDidMount() {
    this.fetchList()
  }
  //1. 路由配置
  //2. 起始页面：传递参数，触发跳转：this.props.history.push('目标页面的路由?key1=value1&key2=value2')
  //3. 目标页面：获取url中的参数，props.location.search

  fetchList() {
    //通过url获取传递的id参数
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    const publisher = params.id ? AV.Object.createWithoutData('_User', params.id) : AV.User.current()
    this.setState({
      loading: true
    })
    const query = new AV.Query('Creation');
    query.equalTo('publisher', publisher)
    query.include('publisher')
    //设立查询条件，指查询当前id对应的挑战的所有创意
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }
  handleOk = e => {
    this.setState({
      visible: false,
    });
  }
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }
  showModal = (item) => {
    this.setState({
      visible: true,
      creation: item
    })
  }
  render() {
    const { data, visible, creation, loading } = this.state
    const { isMobile } = this.props
    return (
      <div>
        <Spin spinning={loading} className="mainWidth">
          {isMobile ?
            //Mobile
            <List
              className="mt10"
              grid={{ gutter: 16, column: 1 }}
              dataSource={data}
              pagination={{
                pageSize: 9
              }}
              renderItem={item => (
                <List.Item onClick={() => this.showModal(item)}>
                  <div className="card-wrap-mobile cursor-pointer">
                    <img className="card-picture-mobile" alt="" src={item.attributes.urls[0]} />
                    <div className="challengedetail-content-wrap">
                      <Paragraph className="challengedetail-content" ellipsis={{ rows: 3 }}>{item.attributes.content}</Paragraph>
                      <div className="publisher-wrap-mobile">
                        <div className="publisher-info">
                          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                          <div className="publisher-name">{item.attributes.publisher.attributes.username}</div>
                        </div>
                        <div className="publisher-focus">
                          <VButton className="follow-button" type="ghost" title="关注" />
                        </div>
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
            :
            //PC
            <List
              className="mt30"
              grid={{ gutter: 16, column: 3 }}
              dataSource={data}
              pagination={{
                pageSize: 9
              }}
              renderItem={item => (
                <List.Item onClick={() => this.showModal(item)}>
                  <div className="card-wrap cursor-pointer">
                    <img className="card-picture" alt="" src={item.attributes.urls[0]} />
                    <div className="challengedetail-content-wrap">
                      <Paragraph className="challengedetail-content" ellipsis={{ rows: 3 }}>{item.attributes.content}</Paragraph>
                      <div className="publisher-wrap">
                        <div className="publisher-info">
                          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
                          <div className="publisher-name">{item.attributes.publisher.attributes.username}</div>
                        </div>
                        <div className="publisher-focus">
                          <VButton className="follow-button" type="ghost" title="关注" />
                        </div>
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          }
        </Spin>
        <CreationDetailModal
          visible={visible}
          onOk={() => this.handleOk()}
          onCancel={() => this.handleCancel()}
          creation={creation}
        ></CreationDetailModal>
      </div>
    )
  }
}

export default HocMedia(ProfileCreation)