import React from 'react'
import NavigationBar from '../component/NavigationBar'
import VButton from '../component/VButton'
import VLabel from '../component/VLabel'
import { List } from 'antd'
import HocMedia from '../utils/HocMedia'
const AV = require('leancloud-storage');

class MuseumList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: []
    }
  }

  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchMuseums()
  }

  fetchMuseums() {
    const that = this
    const query = new AV.Query('Museum');
    query.find().then(result => {
      that.setState({
        data: result
      })
    })
  }

  navigateTo(id) {
    this.props.history.push(`/museumdetail?id=${id}`)
    // this.props.history.push(`/challengedetail/${id}`)
  }

  render() {
    const { data } = this.state;
    const { isMobile } = this.props;
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ?
          //移动端
          <div className="container-wrap-mobile">
            <VLabel title="艺术馆"></VLabel>
            <List
              dataSource={data}
              pagination={{
                pageSize: 5
              }}
              renderItem={item => (
                <List.Item>
                  <div className="content-wrap-mobile">
                  <div className="name text-align-center">{item.attributes.name}</div>
                    <img className="picture-mobile padding-column-10" alt="123" src={item.attributes.picture.thumbnailURL(50, 50)} />
                    <div className="middle-wrap">
                        <div className="number">0篇</div>
                        <div className="popularity">日记/作品</div>
                      </div>
                      <div className="challenge-down-wrap-mobile padding-column-10">
                        专辑描述：{item.attributes.description}
                      </div>
                      <VButton title="查看详情" onClick={() => this.navigateTo(item.id)} />
                  </div>
                </List.Item>
              )}
            />
          </div>
          :
          //PC端
          <div className="container-wrap">
            <VLabel title="艺术馆"></VLabel>
            <List
              dataSource={data}
              pagination={{
                pageSize: 5
              }}
              renderItem={item => (
                <List.Item>
                  <div className="content-wrap">
                    <img className="picture" alt="123" src={item.attributes.picture.thumbnailURL(50, 50)} />
                    <div className="content">
                      <div className="up-wrap">
                        <div className="name">{item.attributes.name}</div>

                        {/* onClick={this.navigateTo.bind(this)}
                      onClick={() => this.navigateTo()} */}

                        <VButton title="查看详情" onClick={() => this.navigateTo(item.id)} />
                      </div>
                      <div className="middle-wrap">
                        <div className="number">0篇</div>
                        <div className="popularity">日记/作品</div>
                      </div>
                      <div className="down-wrap">
                        专辑描述：{item.attributes.description}
                      </div>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </div>
        }
      </div>
    )
  }
}

export default HocMedia(MuseumList)