import React from 'react'
import NavigationBar from '../component/NavigationBar'
import VButton from '../component/VButton'
import VLabel from '../component/VLabel'
import { List, Spin } from 'antd'
import HocMedia from '../utils/HocMedia'
const AV = require('leancloud-storage');

class TopicList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false
    }
  }

  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchTopics()
  }

  fetchTopics() {
    const that = this
    that.setState({
      loading: true
    })
    const query = new AV.Query('Topic');
    query.addAscending('index')
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }

  navigateTo(id) {
    this.props.history.push(`/topicdetail?id=${id}`)
    // this.props.history.push(`/challengedetail/${id}`)
  }

  render() {
    const { data, loading } = this.state;
    const { isMobile } = this.props
    return (
      <Spin spinning={loading} >
        <div>
          <NavigationBar history={this.props.history} />
          {/* judge the code for navigation */}
          {isMobile ?
          //codes for mobile devices start here
           <div className="container-wrap-mobile">
           <VLabel title="话题讨论"></VLabel>
           <List
             dataSource={data}
             pagination={{
               pageSize: 5
             }}
             renderItem={item => (
               <List.Item>
                 <div className="content-wrap-mobile-vertical">
                   <div className="content-wrap-mobile-vertical"onClick={() => this.navigateTo(item.id)} >
                     <div className="topic-up-wrap-mobile-center">
                       <div className="name-mobile" >{item.attributes.title}</div>
                     </div>
                     <div className="picture-container">
                          <img className="pic-mobile" src={item.attributes.picture.url()} alt=""></img>
                     </div>
                     <div className="topic-down-wrap-mobile">
                       {item.attributes.content}
                     </div>
                     <div className="discussion-container">👆点按查看详情👆</div>
                       {/* <VButton  title="➡️" onClick={() => this.navigateTo(item.id)} /> */}
                   </div>
                 </div>
               </List.Item>
             )}
           />
           
         </div>
        //  codes for mobile page construction ends here
        :
        <div className="container-wrap">
        <VLabel title="话题讨论"></VLabel>
        <List
          dataSource={data}
          pagination={{
            pageSize: 5
          }}
          renderItem={item => (
            <List.Item>
              <div className="content-wrap">
                <img className="picture" alt="123" src={item.attributes.picture.thumbnailURL(50, 50)} />
                <div className="content">
                  <div className="up-wrap">
                    <div className="name">{item.attributes.title}</div>


                    <VButton title="查看详情" onClick={() => this.navigateTo(item.id)} />
                  </div>
                  <div className="middle-wrap">
                  </div>
                  <div className="down-wrap">
                    {item.attributes.content}
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
         }
         {/* Anderson's Modify ends here */}
        </div>
      </Spin>
    )
  }
}

export default HocMedia(TopicList)