import React from 'react'
import NavigationBar from '../component/NavigationBar'
import VLabel from '../component/VLabel'
import { List, Spin } from 'antd'
import HocMedia from '../utils/HocMedia'
const AV = require('leancloud-storage');

class Support extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false
    }
  }

  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchMembers()
  }

  fetchMembers() {
    const that = this
    that.setState({
      loading: true
    })
    const query = new AV.Query('Support');
    query.addAscending('index')
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }

  render() {
    const { data, loading } = this.state;
    const { isMobile } = this.props;
    return (
      <Spin spinning={loading} >
        <div>
          <NavigationBar history={this.props.history} />
          <div className="container-wrap">
            <VLabel title="设计 & 开发"></VLabel>
            {isMobile ?
            //移动端
              <List
                className="mainWidth mt30"
                dataSource={data}
                grid={{ gutter: 16, column: 1 }}
                renderItem={item => (
                  <List.Item>
                    <div className="support-wrap">
                      <img className="support-picture" alt="" src={item.attributes.picture.thumbnailURL(50, 50)} />
                      <div className="support-name mt10">{item.attributes.name}</div>
                      <div className="support-role mt10">{item.attributes.role}</div>
                      <div className="support-brief mt20">{item.attributes.brief}</div>
                    </div>
                  </List.Item>
                )}
              />
              :
              //PC端
              <List
                className="mainWidth mt30"
                dataSource={data}
                grid={{ gutter: 16, column: 4 }}
                renderItem={item => (
                  <List.Item>
                    <div className="support-wrap">
                      <img className="support-picture" alt="" src={item.attributes.picture.thumbnailURL(50, 50)} />
                      <div className="support-name mt10">{item.attributes.name}</div>
                      <div className="support-role mt10">{item.attributes.role}</div>
                      <div className="support-brief mt20">{item.attributes.brief}</div>
                    </div>
                  </List.Item>
                )}
              />
            }
          </div>
        </div>
      </Spin>
    )
  }
}

export default HocMedia(Support)