import React from 'react'
import { List } from 'antd'
import NavigationBar from '../component/NavigationBar'
import VButton from '../component/VButton'
import VLabel from '../component/VLabel'
import ReactSwiper from 'reactjs-swiper';
import HocMedia from '../utils/HocMedia'
const AV = require('leancloud-storage');
class Official extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      banners: []
    }
  }
  componentDidMount() {
    this.fetchOfficialBanners()
    this.fetchChallenges()
    this.fetchTopics()
    this.fetchMuseums()
  }
  fetchOfficialBanners() {
    const that = this
    const query = new AV.Query('OfficialBanner')
    query.addAscending('index')
    query.find().then(results => {
      const list = results.map(i => {
        return i.attributes
      })
      that.setState({
        banners: list
      })
    })
  }
  fetchChallenges() {
    const that = this
    const query = new AV.Query('Challenge');
    query.first().then(result => {
      that.setState({
        challenge: result
      })
      that.getChallengeJoinerNumber(result)
      that.getChallengeViewNumber(result)
    })
  }
  fetchTopics() {
    const that = this
    const query = new AV.Query('Topic');
    query.first().then(result => {
      that.setState({
        topic: result
      })
      that.getTopicJoinerNumber(result)
      that.getTopicViewNumber(result)
    })
  }
  fetchMuseums() {
    const that = this
    const query = new AV.Query('Museum');
    query.addAscending('index')
    query.limit(4)
    query.find().then(result => {
      that.setState({
        museum: result
      })
    })
  }
  navigateToMuseums() {
    this.props.history.push('/museum')
  }
  navigateToChallenges() {
    //获取当前页面的路由，并退出新的页面
    this.props.history.push('/challenges')
  }
  navigateToTopics() {
    //获取当前页面的路由，并退出新的页面
    this.props.history.push('/topics')
  }
  navigateToChallengeDetail(id) {
    this.props.history.push(`/challengedetail?id=${id}`)
  }
  navigateToTopicDetail(id) {
    this.props.history.push(`/topicdetail?id=${id}`)
  }
  navigateToMuseumDetail(id) {
    this.props.history.push(`/museumdetail?id=${id}`)
  }
  getTopicJoinerNumber(topic) {
    const that = this
    const query = new AV.Query('Diary');
    query.equalTo('topic', topic)
    query.count().then((count) => {
      that.setState({
        joinerTopicNumber: count
      })
    });
  }
  getTopicViewNumber(topic) {
    const that = this
    const query = new AV.Query('ViewLog');
    query.equalTo('topic', topic)
    query.count().then((count) => {
      that.setState({
        viewTopicNumber: count
      })
    });
  }
  getChallengeJoinerNumber(challenge) {
    const that = this
    const query = new AV.Query('Creation');
    query.equalTo('challenge', challenge)
    query.count().then((count) => {
      that.setState({
        joinerChallengeNumber: count
      })
    });
  }
  getChallengeViewNumber(challenge) {
    const that = this
    const query = new AV.Query('ViewLog');
    query.equalTo('challenge', challenge)
    query.count().then((count) => {
      that.setState({
        viewChallengeNumber: count
      })
    });
  }
  render() {
    const { banners, challenge, topic, museum, joinerChallengeNumber, joinerTopicNumber, viewChallengeNumber, viewTopicNumber } = this.state
    const { isMobile } = this.props
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ?
          //移动端
          <div className="container-wrap-mobile">
            <ReactSwiper swiperOptions={{
              preloadImages: true,
              autoplay: 4000,
              autoplayDisableOnInteraction: false
            }} showPagination items={banners}
              className="home-swiper" />
            {challenge && <div className="challenge-wrap-mobile">
              <div className="title-wrap">
                <VLabel title="创意挑战"></VLabel>
                <div className="more cursor-pointer" onClick={() => this.navigateToChallenges()}>查看更多</div>
              </div>
              <div className="content-wrap-mobile">
                <div className="challenge-up-wrap-mobile">
                  <div className="name text-align-center">{challenge.attributes.title}</div>
                  <img className="picture-mobile" alt="" src={challenge.attributes.picture.thumbnailURL(50, 50)} />
                  <div className="middle-wrap">
                    <div className="number">参与人数：{joinerChallengeNumber}</div>
                    <div className="popularity">热度：{viewChallengeNumber}</div>
                  </div>
                  <div className="challenge-down-wrap-mobile padding-column-10">
                    {challenge.attributes.content}
                  </div>
                  <VButton title="查看详情" onClick={() => this.navigateToChallengeDetail(challenge.id)} />
                </div>
              </div>
            </div>}
            {topic && <div className="topic-wrap">
              <div className="title-wrap mt20">
                <VLabel title="话题讨论"></VLabel>
                <div className="more cursor-pointer" onClick={() => this.navigateToTopics()}>查看更多</div>
              </div>
              <div className="topicDetail-content-wrap-mobile">
                <div className="topicDetail-up-wrap-mobile">
                  <img className="topicDetail-picture-mobile" alt="" src={topic && topic.attributes.picture.thumbnailURL(50, 50)} />
                  <div className="topicDetail-up-right-wrap-mobile">
                    <div className="topicDetail-name-mobile">{topic && topic.attributes.title}</div>
                    <VButton className="button-width" title="参与讨论" onClick={() => this.showModal()} />
                  </div>
                </div>
                <div className="topicDetail-content-mobile">
                  <div className="topicDetail-middle-wrap-mobile">
                    <div className="number">参与人数：{joinerTopicNumber}</div>
                    <div className="popularity">热度：{viewTopicNumber}</div>
                  </div>
                  <div className="topicDetail-down-wrap-mobile">{topic && topic.attributes.content}</div>
                </div>
              </div>
              {/* <div className="content-wrap mt10">
                <img className="picture" alt="" src={topic.attributes.picture.thumbnailURL(50, 50)} />
                <div className="content">
                  <div className="up-wrap">
                    <div className="name">{topic.attributes.title}</div>
                    <VButton title="查看详情" onClick={() => this.navigateToTopicDetail(topic.id)} />
                  </div>
                  <div className="middle-wrap">
                    <div className="number">参与人数：{joinerTopicNumber}</div>
                    <div className="popularity">热度：{viewTopicNumber}</div>
                  </div>
                  <div className="down-wrap">
                    {topic.attributes.content}
                  </div>
                </div>
              </div> */}
            </div>}

            {museum &&
              <div className="museum-wrap">
                <div className="title-wrap mt20">
                  <VLabel title="艺术馆"></VLabel>
                  <div className="more cursor-pointer" onClick={() => this.navigateToMuseums()}>查看更多</div>
                </div>
                <div className="mainWidth mt10">
                  <List
                    grid={{ gutter: 16, column: 1 }}
                    dataSource={museum}
                    renderItem={item => (
                      <List.Item>
                        <div style={{ position: 'relative' }}>
                          <div >
                            <img className="museum-picture-mobile" alt="" src={item.attributes.picture.thumbnailURL(234, 234)} />
                          </div>
                          <div className="museum-image-wrap">
                            <h1 className="entry-title">
                              <div className="featured-image" onClick={() => this.navigateToMuseumDetail(item.id)}>{item.attributes.name}</div>
                            </h1>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            }
          </div>
          :
          //PC端
          <div className="container-wrap">
            <ReactSwiper swiperOptions={{
              preloadImages: true,
              autoplay: 4000,
              autoplayDisableOnInteraction: false
            }} showPagination items={banners}
              className="home-swiper" />
            {challenge && <div className="challenge-wrap">
              <div className="title-wrap">
                <VLabel title="创意挑战"></VLabel>
                <div className="more cursor-pointer" onClick={() => this.navigateToChallenges()}>查看更多</div>
              </div>
              <div className="content-wrap mt10">
                <img className="picture" alt="" src={challenge.attributes.picture.thumbnailURL(50, 50)} />
                <div className="content">
                  <div className="up-wrap">
                    <div className="name">{challenge.attributes.title}</div>

                    {/* onClick={this.navigateTo.bind(this)}
                      onClick={() => this.navigateTo()} */}

                    <VButton title="查看详情" onClick={() => this.navigateToChallengeDetail(challenge.id)} />
                  </div>
                  <div className="middle-wrap">
                    <div className="number">参与人数：{joinerChallengeNumber}</div>
                    <div className="popularity">热度：{viewChallengeNumber}</div>
                  </div>
                  <div className="down-wrap">
                    {challenge.attributes.content}
                  </div>
                </div>
              </div>
            </div>}
            {topic && <div className="topic-wrap">
              <div className="title-wrap mt20">
                <VLabel title="话题讨论"></VLabel>
                <div className="more cursor-pointer" onClick={() => this.navigateToTopics()}>查看更多</div>
              </div>
              <div className="content-wrap mt10">
                <img className="picture" alt="" src={topic.attributes.picture.thumbnailURL(50, 50)} />
                <div className="content">
                  <div className="up-wrap">
                    <div className="name">{topic.attributes.title}</div>

                    {/* onClick={this.navigateTo.bind(this)}
                      onClick={() => this.navigateTo()} */}

                    <VButton title="查看详情" onClick={() => this.navigateToTopicDetail(topic.id)} />
                  </div>
                  <div className="middle-wrap">
                    <div className="number">参与人数：{joinerTopicNumber}</div>
                    <div className="popularity">热度：{viewTopicNumber}</div>
                  </div>
                  <div className="down-wrap">
                    {topic.attributes.content}
                  </div>
                </div>
              </div>
            </div>}

            {museum &&
              <div className="museum-wrap">
                <div className="title-wrap mt20">
                  <VLabel title="艺术馆"></VLabel>
                  <div className="more cursor-pointer" onClick={() => this.navigateToMuseums()}>查看更多</div>
                </div>
                <div className="mainWidth mt10">
                  <List
                    grid={{ gutter: 16, column: 4 }}
                    dataSource={museum}
                    renderItem={item => (
                      <List.Item>
                        <div style={{ position: 'relative' }}>
                          <div >
                            <img className="museum-picture" alt="" src={item.attributes.picture.thumbnailURL(234, 234)} />
                          </div>
                          <div className="museum-image-wrap">
                            <h1 className="entry-title">
                              <div className="featured-image" onClick={() => this.navigateToMuseumDetail(item.id)}>{item.attributes.name}</div>
                            </h1>
                          </div>
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            }
          </div>
        }

      </div>
    )
  }
}

export default HocMedia(Official)