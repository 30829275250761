import React from 'react'
import moment from 'moment'
import VButton from '../../component/VButton'
import { TagMap } from '../../utils/utils'
import { Avatar, Modal, List, Input, message } from 'antd'
import { UserOutlined, HeartOutlined, MessageOutlined, MoreOutlined } from '@ant-design/icons';
import styles from './index.module.css'
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');
class Content extends React.Component {
  constructor(props) {
    super(props)
    // console.log('props', props.visible)
    this.state = {
      comments: []
    }
  }
  componentDidMount() {
    this.fetchComments()
  }

  changeComment(e) {
    this.setState({
      comment: e.currentTarget.value
    })
  }
  postComment = () => {
    const { diary, toSignin } = this.props
    const { comment } = this.state
    const that = this
    if (!AV.User.current()) {
      message.warn('请先登录')
      if (toSignin) {
        toSignin()
      }
      return
    }
    if (!comment) {
      message.warn('请输入评论内容')
      return
    }
    const com = AV.Object("Comment")
    com.set('content', comment)
    com.set('sender', AV.User.current())
    // com.set('receiver', )
    com.set('targetDiary', diary)
    com.save().then(res => {
      message.success('评论成功')
      that.fetchComments()
      that.setState({
        comment: undefined
      })
    })
  }
  fetchComments() {
    const { diary } = this.props
    if (!diary) {
      return
    }
    const that = this
    // let diary = AV.Object.createWithoutData('Diary', item.id)
    const queryComment = new AV.Query('Comment')
    queryComment.equalTo('targetDiary', diary)
    queryComment.include('sender')
    queryComment.descending('createdAt')
    queryComment.find().then(res => {
      that.setState({
        comments: res
      })
    })
  }
  render() {
    const { diary = {}, isMobile } = this.props
    const createdAt = moment(diary.createdAt).format('YYYY-MM-DD HH:mm')
    const content = (diary.attributes && diary.attributes.content) || ''
    const title = diary.attributes && diary.attributes.title
    const type = diary.attributes && diary.attributes.type
    const { username, avatar } = (diary.attributes && diary.attributes.publisher.attributes) || {}
    const { comments, comment } = this.state
    return (
      <>
        {isMobile ?
          //Mobile
          <div className="detail-modal-mobile">
            <div className="diary-wrap-mobile">
              {title && <div className="diary-title">{title}</div>}
              <div className="diary-content" dangerouslySetInnerHTML={{ __html: `${content}` }} />
            </div>
            <div className="detail-wrap-mobile">
              <div className="diary-post-wrap-mobile">
                <div className="post-publisher-wrap">
                  <div className="publisher-info">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                    <div className="post-publisher-info">
                      <div className="publisher-name">{username}</div>
                      <div className="post-publisher-time">{createdAt}</div>
                    </div>
                  </div>
                  <div className="publisher-focus">
                    <VButton className="follow-button" type="ghost" title="关注" />
                  </div>
                </div>
                {type && <div className="feeds-tag">#{TagMap[type].text}</div>}
                <div className="flex-sb mt10">
                  <div className="flex-sb">
                    <HeartOutlined className="fs16 mr20" />
                    <MessageOutlined className="fs16" />
                  </div>
                  <MoreOutlined className="fs16" />
                </div>
              </div>
              <div className="diary-comment-wrap-mobile">
                {/* 评论 */}
                <List
                  className="feeds-comments"
                  dataSource={comments}
                  renderItem={item => (
                    <List.Item>
                      <div className="feeds-comments-wrap">
                        <div className="feeds-comment-content-wrap">
                          <div className="feeds-publisher-wrap">
                            <div className="feeds-publisher-info">
                              <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                              <div className="ml10">
                                <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                              </div>
                            </div>
                            <div className="publisher-focus">
                              {/* <VButton className="follow-button" type="ghost" title="关注" /> */}
                            </div>
                          </div>
                          <div className="feeds-content">{item.attributes.content}</div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
              <div className="comment-post-wrap-mobile">
                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                <div className="comment-input-mobile">
                  <Input value={comment} placeholder="写评论..." bordered={false} onChange={(e) => this.changeComment(e)}></Input>
                  <VButton title="发送" backClass={styles.send} titleClass={styles.sendTitle} onClick={this.postComment} />
                </div>
              </div>
            </div>
          </div>
          :
          //PC
          <div className="detail-modal">
            <div className="diary-wrap">
              {title && <div className="diary-title">{title}</div>}
              <div className="diary-content" dangerouslySetInnerHTML={{ __html: `${content}` }} />
            </div>
            <div className="detail-wrap">
              <div className="diary-post-wrap">
                <div className="post-publisher-wrap">
                  <div className="publisher-info">
                    <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                    <div className="post-publisher-info">
                      <div className="publisher-name">{username}</div>
                      <div className="post-publisher-time">{createdAt}</div>
                    </div>
                  </div>
                  <div className="publisher-focus">
                    <VButton className="follow-button" type="ghost" title="关注" />
                  </div>
                </div>
                {type && <div className="feeds-tag">#{TagMap[type].text}</div>}
                <div className="flex-sb mt10">
                  <div className="flex-sb">
                    <HeartOutlined className="fs16 mr20" />
                    <MessageOutlined className="fs16" />
                  </div>
                  <MoreOutlined className="fs16" />
                </div>
              </div>
              <div className="diary-comment-wrap">
                {/* 评论 */}
                <List
                  className="feeds-comments"
                  dataSource={comments}
                  renderItem={item => (
                    <List.Item>
                      <div className="feeds-comments-wrap">
                        <div className="feeds-comment-content-wrap">
                          <div className="feeds-publisher-wrap">
                            <div className="feeds-publisher-info">
                              <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                              <div className="ml10">
                                <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                              </div>
                            </div>
                            <div className="publisher-focus">
                              {/* <VButton className="follow-button" type="ghost" title="关注" /> */}
                            </div>
                          </div>
                          <div className="feeds-content">{item.attributes.content}</div>
                        </div>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
              <div className="comment-post-wrap">
                <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={avatar && avatar.thumbnailURL(32, 32)} />
                <div className="comment-input">
                  <Input value={comment} placeholder="写评论..." bordered={false} onChange={(e) => this.changeComment(e)}></Input>
                  <VButton title="发送" backClass={styles.send} titleClass={styles.sendTitle} onClick={this.postComment} />
                </div>
              </div>
            </div>
          </div>
        }
      </>
    )
  }
}

class Index extends React.Component {
  render() {
    const { visible, onCancel, isMobile } = this.props;
    return (
      <>
        {
          isMobile ?
            (<Modal
              style={{ width: "100%" }}
              // title="创作详情"
              visible={visible}
              onCancel={onCancel}
              footer={null}
              destroyOnClose={true}
            >
              <Content {...this.props} />
            </Modal >)
            :
            <Modal
              style={{ minWidth: 940, maxHeight: 600 }}
              // title="创作详情"
              visible={visible}
              onCancel={onCancel}
              footer={null}
              destroyOnClose={true}
            >
              <Content {...this.props} />
            </Modal>

        }
      </>
    );
  }
}

export default HocMedia(Index)