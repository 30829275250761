import React from 'react';
import styles from './index.module.css';

export default class VButtonItem extends React.Component {
  // constructor(props) {
  //   super(props)
  //   console.log({ ...props })
  // }
  render() {
    const { className, imgName, title, onClick } = this.props;
    return (
      <div className={`${styles.item} ${className}`} onClick={onClick}>
        <img className={styles.image} src={require(`../../resources/${imgName}.png`)} alt="" />
        <div className={styles.title}>{title}</div>
      </div>
    )
  }
}