import React from 'react';
import moment from 'moment'
import '../App.css'
import { List, Avatar, Spin, message, Typography, Button, Popover, Layout } from 'antd';
import { UserOutlined, HeartOutlined, MessageOutlined, MoreOutlined } from '@ant-design/icons';
import NavigationBar from '../component/NavigationBar';
import { TagMap } from '../utils/utils'
import { Link } from 'react-router-dom';
import ReportModal from '../component/ReportModal'
import SigninModal from '../component/SigninModal'
import PostPictureModal from '../component/PostPictureModal'
import PostDiaryModal from '../component/PostDiaryModal'
import DiaryDetailModal from '../component/DiaryDetailModal'
import CreationDetailModal from '../component/CreationDetailModal'
import VButtonItem from '../component/VButtonItem'
import VButton from '../component/VButton'
import ReactSwiper from 'reactjs-swiper';
import challengeIcon from '../resources/challenge.png';
import topicIcon from '../resources/topic.png';
import { getQuery } from '../utils/utils';
import HocMedia from '../utils/HocMedia'
import VLabel from '../component/VLabel'
import VMenu from '../component/VMenu'
const { Footer } = Layout;
const { Paragraph } = Typography
const AV = require('leancloud-storage');
// 创建组件类
class Home extends React.Component {
  // 构造函数
  constructor(props) {
    super(props)
    // 初始化设置
    this.state = {
      current: 'creation',
      followeeIds: [],
      loadingCreation: false,
      loadingDiary: false,
      visible: false,
      visibleReport: false,
      postPictureVisible: false,
      postDiaryVisible: false,
      skip: 0,
      comments: {},
      banners: [],
      loading: false,
      hasMore: true,
      creations: [],
      diaries: []
    }
  }
  fetchCreationDiary = (e) => {
    const search = this.props.location.search
    const params = getQuery(search)
    const type = e || params.type
    const { skip, comments, creations, diaries } = this.state
    const that = this
    const query = new AV.Query('Creation')
    if (type) {
      query.equalTo('type', type)
    }
    query.include('publisher')
    query.limit(2)
    query.skip(skip)
    query.descending('createdAt')
    this.setState({
      loadingDiary: true,
      loadingCreation: true,
    })
    query.find().then(res => {
      that.setState({
        creations: [...creations, ...res],
        loadingCreation: false,
        loading: false,
        skip: skip + 3
      })
      for (let item of res) {
        let creation = AV.Object.createWithoutData('Creation', item.id)
        const queryComment = new AV.Query('Comment')
        queryComment.equalTo('targetCreation', creation)
        queryComment.include('sender')
        queryComment.limit(2)
        queryComment.descending('createdAt')
        queryComment.find().then(res => {
          that.setState({
            comments: {
              ...comments,
              [item.id]: res
            }
          })
        })
      }
    })

    const queryDiary = new AV.Query('Diary')
    if (type) {
      queryDiary.equalTo('type', type)
    }
    queryDiary.include('publisher')
    queryDiary.limit(3)
    queryDiary.skip(skip)
    queryDiary.descending('createdAt')
    queryDiary.find().then(res => {
      that.setState({
        diaries: [...diaries, ...res],
        loadingDiary: false,
        loading: false,
      })
      for (let item of res) {
        let diary = AV.Object.createWithoutData('Diary', item.id)
        const queryComment = new AV.Query('Comment')
        queryComment.equalTo('targetDiary', diary)
        queryComment.include('sender')
        queryComment.limit(2)
        queryComment.descending('createdAt')
        queryComment.find().then(res => {
          that.setState({
            comments: {
              ...comments,
              [item.id]: res
            }
          })
        })
      }
    })
  }
  toSignin = () => {
    this.setState({
      visible: true,
      visibleDiary: false,
      visibleCreation: false
    })
  }
  //滚动加载
  handleInfiniteOnLoad = () => {
    this.setState({
      loading: true,
    });
    this.fetchCreationDiary()
  };
  handleCancel() {
    this.setState({
      visibleReport: false,
      visible: false,
      visibleDiary: false,
      visibleCreation: false,
    })
  }
  handleCancelPostPicture() {
    this.setState({
      draftCreation: undefined,
      postPictureVisible: false
    })
  }
  handleCancelPostDiary() {
    this.setState({
      draftCreation: undefined,
      postDiaryVisible: false
    })
  }
  //网络请求
  fetchBanners() {
    const that = this
    const query = new AV.Query('Banner')
    query.addAscending('index')
    query.find().then(results => {
      const list = results.map(i => {
        return i.attributes
      })
      that.setState({
        banners: list
      })
    })
  }
  fetchFollowee() {
    const that = this
    var query = new AV.Query('Followers');
    query.equalTo('toUser', AV.User.current())
    query.include('fromUser');
    query.find().then(function (followees) {
      const followeeIds = followees.map(i => {
        return i.id
      })
      if (AV.User.current()) {
        followeeIds.push(AV.User.current().id)
      }
      that.setState({
        followeeIds
      })
      that.fetchCreationDiary()
    });
  }
  showPostPicture() {
    if (!AV.User.current()) {
      this.toSignin()
      return
    }
    this.setState({
      isDraft: false,
      postPictureVisible: true
    })
  }
  showPostDiary() {
    if (!AV.User.current()) {
      this.toSignin()
      return
    }
    this.setState({
      isDraft: false,
      postDiaryVisible: true
    })
  }
  showDraft() {
    if (!AV.User.current()) {
      this.toSignin()
      return
    }
    this.fetchDraft()
  }
  fetchDraft() {
    const that = this
    const query = new AV.Query('Draft')
    query.equalTo('published', false)
    query.descending('createdAt')
    query.first().then(res => {
      if (!res) {
        message.info('暂无草稿')
        return
      }
      if (res.attributes.category === 'creation') {
        that.setState({
          isDraft: true,
          postPictureVisible: true,
          draftCreation: res
        })
        return
      } else if (res.attributes.category === 'diary') {
        that.setState({
          isDraft: true,
          postDiaryVisible: true,
          draftDiary: res
        })
        return
      }
      message.info('暂无草稿')
    })
  }
  //生命周期
  componentDidMount() {
    this.fetchBanners()
    this.fetchFollowee()
  }
  showDiaryDetail = (item) => {
    this.setState({
      visibleDiary: true,
      selectDiary: item
    })
  }
  showCreationDetail = (item) => {
    this.setState({
      visibleCreation: true,
      selectCreation: item
    })
  }
  menuClicked(e) {
    this.fetchCreationDiary(e)
  }
  navigateToProfile(item) {
    if (!AV.User.current()) {
      this.toSignin()
      return
    }
    if (item.attributes.publisher.id === AV.User.current().id) {
      this.props.history.push('/profile')
    } else {
      this.props.history.push(`/profileother?id=${item.attributes.publisher.id}`)
    }

  }
  handleFollow = (item) => {
    const that = this
    if (!AV.User.current()) {
      this.toSignin()
      return
    }
    const follow = new AV.Object('Followers')
    follow.set('toUser', item.attributes.publisher)
    follow.set('fromUser', AV.User.current())
    follow.save().then(res => {
      message.success('已关注')
      that.fetchFollowee()
    })
  }
  reloadHomeList = () => {
    this.fetchFollowee()
  }
  report(item, type) {
    const reportItem = {
      ...item,
      reportType: type
    }
    this.setState({
      visibleReport: true,
      selectedReportItem: reportItem
    })
  }
  handleHoveredMore = (item, visible) => {
    this.setState({
      hoveredMore: visible,
      hoveredMoreId: item.id
    })
  }
  handleMenuClick(key) {
    this.setState({
      current: key
    })
  }
  // 渲染函数
  render() {
    const { current, followeeIds, comments, visible, postPictureVisible, postDiaryVisible, banners, creations, diaries, selectDiary, visibleDiary, selectCreation, visibleCreation, loadingCreation, loadingDiary,
      draftDiary, draftCreation, isDraft, hoveredMore, hoveredMoreId, visibleReport, selectedReportItem } = this.state
    const { isMobile } = this.props;
    return (
      <Layout>
        <NavigationBar history={this.props.history} onMenuClicked={(e) => this.menuClicked(e)} />
        {
          isMobile ?
            //移动端
            <div className="container">
              <ReactSwiper swiperOptions={{
                preloadImages: true,
                autoplay: 4000,
                autoplayDisableOnInteraction: false
              }} showPagination items={banners}
                className="home-swiper" />
              <div className="home-options-mobile">
                <div className="home-post-mobile">
                  <VButtonItem imgName="create" title="发创作" onClick={(() => this.showPostPicture())} />
                  <VButtonItem imgName="article" title="发日记" onClick={(() => this.showPostDiary())} />
                  <VButtonItem imgName="draft" title="草稿箱" onClick={(() => this.showDraft())} />
                </div>
                <div>
                  <Link to="/challenges"><img src={challengeIcon} alt="" className="home-activity-mobile" /></Link>
                </div>
                <div>
                  <Link to="/topics"><img src={topicIcon} alt="" className="home-activity-mobile" /></Link>
                </div>
              </div>
              <Spin spinning={loadingCreation || loadingDiary}>
                <VMenu className="home-tab-mobile">
                  <VLabel title="创作" backgroundClassName="mr50" showLine={current === 'creation'} onClick={() => this.handleMenuClick('creation')}></VLabel>
                  <VLabel title="日记" showLine={current === 'diary'} onClick={() => this.handleMenuClick('diary')}></VLabel>
                </VMenu>
                {
                  current === 'creation' ?
                    <>
                      <List
                        className="home-list-mobile"
                        grid={{
                          column: 1
                        }}
                        dataSource={creations}
                        renderItem={item => (
                          <List.Item>
                            <div className="feeds-wrap-mobile">
                              <div className="feeds-wrap-up-mobile">
                                <div className="feeds-content-wrap">
                                  <div className="feeds-publisher-wrap">
                                    <div className="feeds-publisher-info">
                                      <Avatar className="cursor-pointer" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} onClick={() => this.navigateToProfile(item)} />
                                      <div className="ml10">
                                        <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                                        <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                                      </div>
                                    </div>
                                    {
                                      !followeeIds.includes(item.attributes.publisher.id) &&
                                      <div className="publisher-focus">
                                        <VButton className="follow-button" type="ghost" title="关注" onClick={() => this.handleFollow(item)} />
                                      </div>
                                    }
                                  </div>
                                  <Paragraph className="feeds-content" ellipsis={{ rows: 2 }}>{item.attributes.content}</Paragraph>
                                </div>
                                <img className="feeds-picture-mobile cursor-pointer" alt="" src={item.attributes.urls[0]} onClick={() => this.showCreationDetail(item)} />
                                {item.attributes.type && <div className="feeds-tag">#{TagMap[item.attributes.type].text}</div>}
                                <div className="flex-sb mb10">
                                  <div className="flex-sb">
                                    <HeartOutlined className="fs16 mr20" />
                                    <MessageOutlined className="fs16" />
                                  </div>
                                  <Popover
                                    key={item.id}
                                    content={
                                      <div className="cursor-pointer" onClick={() => { this.report(item, 'creation') }}>
                                        举报
                                      </div>
                                    }
                                    trigger="hover"
                                    visible={hoveredMore && hoveredMoreId === item.id}
                                    onVisibleChange={(visible) => this.handleHoveredMore(item, visible)}
                                  >
                                    <MoreOutlined style={{ width: 30, height: 30 }} className="fs16 cursor-pointer" />
                                  </Popover>

                                </div>
                              </div>
                              {/* 评论 */}
                              {comments[item.id] && <List
                                className="feeds-comments"
                                dataSource={comments[item.id] || []}
                                renderItem={item => (
                                  <List.Item>
                                    <div className="feeds-comments-wrap">
                                      <div className="feeds-comment-content-wrap">
                                        <div className="feeds-publisher-wrap">
                                          <div className="feeds-publisher-info">
                                            <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                                            <div className="ml10">
                                              <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                              <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                                            </div>
                                          </div>
                                          <div className="publisher-focus">
                                            <div className="cursor-pointer color-666 fs14" onClick={() => { this.report(item, 'comment') }}>举报</div>
                                          </div>
                                          {/* <div className="publisher-focus">
                                    <VButton className="follow-button" type="ghost" title="点赞" />
                                  </div> */}
                                        </div>
                                        <div className="feeds-content">{item.attributes.content}</div>
                                      </div>
                                    </div>
                                  </List.Item>
                                )}
                              />}
                            </div>
                          </List.Item>
                        )}
                      />
                      <div className="flex-center mb30">
                        <Button className="w100p" onClick={this.handleInfiniteOnLoad} type="link">加载更多</Button>
                      </div>
                    </>
                    :
                    <>
                      <List
                        className="home-list-mobile"
                        grid={{
                          column: 1
                        }}
                        dataSource={diaries}
                        renderItem={item => (
                          <List.Item>
                            <div className="feeds-wrap-mobile">
                              <div className="feeds-wrap-up-mobile">
                                <div className="feeds-content-wrap">
                                  <div className="feeds-publisher-wrap">
                                    <div className="feeds-publisher-info">
                                      <Avatar className="cursor-pointer" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} onClick={() => this.navigateToProfile(item)} />
                                      <div className="ml10">
                                        <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                                        <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                                      </div>
                                    </div>
                                    {
                                      !followeeIds.includes(item.attributes.publisher.id) &&
                                      <div className="publisher-focus">
                                        <VButton className="follow-button" type="ghost" title="关注" onClick={() => this.handleFollow(item)} />
                                      </div>
                                    }
                                  </div>
                                  <Paragraph className="title mt10" ellipsis={{ rows: 2 }}>{item.attributes.title}</Paragraph>
                                  <Paragraph className="feeds-content mt0" ellipsis={{ rows: 10 }}>{item.attributes.textContent}</Paragraph>
                                  <div className="theme-color fs14 cursor-pointer" onClick={() => this.showDiaryDetail(item)}>查看完整内容</div>
                                </div>
                                {item.attributes.type && <div className="feeds-tag">#{TagMap[item.attributes.type].text}</div>}
                                <div className="flex-sb mb10">
                                  <div className="flex-sb">
                                    <HeartOutlined className="fs16 mr20" />
                                    <MessageOutlined className="fs16" />
                                  </div>
                                  <Popover
                                    key={item.id}
                                    content={
                                      <div className="cursor-pointer" onClick={() => { this.report(item, 'diary') }}>
                                        举报
                                      </div>
                                    }
                                    trigger="hover"
                                    visible={hoveredMore && hoveredMoreId === item.id}
                                    onVisibleChange={(visible) => this.handleHoveredMore(item, visible)}
                                  >
                                    <MoreOutlined style={{ width: 30, height: 30 }} className="fs16 cursor-pointer" />
                                  </Popover>
                                </div>
                              </div>
                              {/* 评论 */}
                              {comments[item.id] && <List
                                className="feeds-comments"
                                dataSource={comments[item.id] || []}
                                renderItem={item => (
                                  <List.Item>
                                    <div className="feeds-comments-wrap">
                                      <div className="feeds-comment-content-wrap">
                                        <div className="feeds-publisher-wrap">
                                          <div className="feeds-publisher-info">
                                            <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                                            <div className="ml10">
                                              <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                              <div className="feeds-publisher-date">{item.attributes.date}</div>
                                            </div>
                                          </div>
                                          <div className="publisher-focus">
                                            <div className="cursor-pointer color-666 fs14" onClick={() => { this.report(item, 'comment') }}>举报</div>
                                          </div>
                                          {/* <div className="publisher-focus"> */}
                                          {/* <VButton className="follow-button" type="ghost" title="点赞" /> */}
                                          {/* </div> */}
                                        </div>
                                        <div className="feeds-content">{item.attributes.content}</div>
                                      </div>
                                    </div>
                                  </List.Item>
                                )}
                              />}
                            </div>
                          </List.Item>
                        )}
                      />
                      <div className="flex-center mb30">
                        <Button className="w100p" onClick={this.handleInfiniteOnLoad} type="link">加载更多</Button>
                      </div>
                    </>
                }
              </Spin>
            </div>
            :
            //PC端
            <div className="container">
              <ReactSwiper swiperOptions={{
                preloadImages: true,
                autoplay: 4000,
                autoplayDisableOnInteraction: false
              }} showPagination items={banners}
                className="home-swiper" />
              <div className="home-options">
                <div className="home-post">
                  <VButtonItem imgName="create" title="发创作" onClick={(() => this.showPostPicture())} />
                  <VButtonItem imgName="article" title="发日记" onClick={(() => this.showPostDiary())} />
                  <VButtonItem imgName="draft" title="草稿箱" onClick={(() => this.showDraft())} />
                </div>
                <div>
                  <Link to="/challenges"><img className="option-image" src={challengeIcon} alt="" className="home-activity" /></Link>
                </div>
                <div>
                  <Link to="/topics"><img src={topicIcon} alt="" className="home-activity" /></Link>
                </div>
              </div>
              <Spin spinning={loadingCreation || loadingDiary}>
                <div className="home-list-wrap">
                  <List
                    className="home-list"
                    grid={{
                      column: 1
                    }}
                    dataSource={creations}
                    renderItem={item => (
                      <List.Item>
                        <div className="feeds-wrap">
                          <div className="feeds-wrap-up">
                            <div className="feeds-content-wrap">
                              <div className="feeds-publisher-wrap">
                                <div className="feeds-publisher-info">
                                  <Avatar className="cursor-pointer" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} onClick={() => this.navigateToProfile(item)} />
                                  <div className="ml10">
                                    <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                                    <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                                  </div>
                                </div>
                                {
                                  !followeeIds.includes(item.attributes.publisher.id) &&
                                  <div className="publisher-focus">
                                    <VButton className="follow-button" type="ghost" title="关注" onClick={() => this.handleFollow(item)} />
                                  </div>
                                }
                              </div>
                              <Paragraph className="feeds-content" ellipsis={{ rows: 2 }}>{item.attributes.content}</Paragraph>
                            </div>
                            <img className="feeds-picture cursor-pointer" alt="" src={item.attributes.urls[0]} onClick={() => this.showCreationDetail(item)} />
                            {item.attributes.type && <div className="feeds-tag">#{TagMap[item.attributes.type].text}</div>}
                            <div className="flex-sb mb10">
                              <div className="flex-sb">
                                <HeartOutlined className="fs16 mr20" />
                                <MessageOutlined className="fs16" />
                              </div>
                              <Popover
                                key={item.id}
                                content={
                                  <div className="cursor-pointer" onClick={() => { this.report(item, 'creation') }}>
                                    举报
                                  </div>
                                }
                                trigger="hover"
                                visible={hoveredMore && hoveredMoreId === item.id}
                                onVisibleChange={(visible) => this.handleHoveredMore(item, visible)}
                              >
                                <MoreOutlined style={{ width: 30, height: 30 }} className="fs16 cursor-pointer" />
                              </Popover>

                            </div>
                          </div>
                          {/* 评论 */}
                          {comments[item.id] && <List
                            className="feeds-comments"
                            dataSource={comments[item.id] || []}
                            renderItem={item => (
                              <List.Item>
                                <div className="feeds-comments-wrap">
                                  <div className="feeds-comment-content-wrap">
                                    <div className="feeds-publisher-wrap">
                                      <div className="feeds-publisher-info">
                                        <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                                        <div className="ml10">
                                          <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                          <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                                        </div>
                                      </div>
                                      <div className="publisher-focus">
                                        <div className="cursor-pointer color-666 fs14" onClick={() => { this.report(item, 'comment') }}>举报</div>
                                      </div>
                                      {/* <div className="publisher-focus">
                                    <VButton className="follow-button" type="ghost" title="点赞" />
                                  </div> */}
                                    </div>
                                    <div className="feeds-content">{item.attributes.content}</div>
                                  </div>
                                </div>
                              </List.Item>
                            )}
                          />}
                        </div>
                      </List.Item>
                    )}
                  />
                  <List
                    className="home-list"
                    grid={{
                      column: 1
                    }}
                    dataSource={diaries}
                    renderItem={item => (
                      <List.Item>
                        <div className="feeds-wrap">
                          <div className="feeds-wrap-up">
                            <div className="feeds-content-wrap">
                              <div className="feeds-publisher-wrap">
                                <div className="feeds-publisher-info">
                                  <Avatar className="cursor-pointer" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} onClick={() => this.navigateToProfile(item)} />
                                  <div className="ml10">
                                    <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                                    <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                                  </div>
                                </div>
                                {
                                  !followeeIds.includes(item.attributes.publisher.id) &&
                                  <div className="publisher-focus">
                                    <VButton className="follow-button" type="ghost" title="关注" onClick={() => this.handleFollow(item)} />
                                  </div>
                                }
                              </div>
                              <Paragraph className="title mt10" ellipsis={{ rows: 2 }}>{item.attributes.title}</Paragraph>
                              <Paragraph className="feeds-content mt0" ellipsis={{ rows: 10 }}>{item.attributes.textContent}</Paragraph>
                              <div className="theme-color fs14 cursor-pointer" onClick={() => this.showDiaryDetail(item)}>查看完整内容</div>
                            </div>
                            {item.attributes.type && <div className="feeds-tag">#{TagMap[item.attributes.type].text}</div>}
                            <div className="flex-sb mb10">
                              <div className="flex-sb">
                                <HeartOutlined className="fs16 mr20" />
                                <MessageOutlined className="fs16" />
                              </div>
                              <Popover
                                key={item.id}
                                content={
                                  <div className="cursor-pointer" onClick={() => { this.report(item, 'diary') }}>
                                    举报
                                  </div>
                                }
                                trigger="hover"
                                visible={hoveredMore && hoveredMoreId === item.id}
                                onVisibleChange={(visible) => this.handleHoveredMore(item, visible)}
                              >
                                <MoreOutlined style={{ width: 30, height: 30 }} className="fs16 cursor-pointer" />
                              </Popover>
                            </div>
                          </div>
                          {/* 评论 */}
                          {comments[item.id] && <List
                            className="feeds-comments"
                            dataSource={comments[item.id] || []}
                            renderItem={item => (
                              <List.Item>
                                <div className="feeds-comments-wrap">
                                  <div className="feeds-comment-content-wrap">
                                    <div className="feeds-publisher-wrap">
                                      <div className="feeds-publisher-info">
                                        <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.sender.attributes.avatar && item.attributes.sender.attributes.avatar.thumbnailURL(32, 32)} />
                                        <div className="ml10">
                                          <div className="feeds-publisher-name">{item.attributes.sender.attributes.username}</div>
                                          <div className="feeds-publisher-date">{item.attributes.date}</div>
                                        </div>
                                      </div>
                                      <div className="publisher-focus">
                                        <div className="cursor-pointer color-666 fs14" onClick={() => { this.report(item, 'comment') }}>举报</div>
                                      </div>
                                      {/* <div className="publisher-focus"> */}
                                      {/* <VButton className="follow-button" type="ghost" title="点赞" /> */}
                                      {/* </div> */}
                                    </div>
                                    <div className="feeds-content">{item.attributes.content}</div>
                                  </div>
                                </div>
                              </List.Item>
                            )}
                          />}
                        </div>
                      </List.Item>
                    )}
                  />
                </div>
                <div className="flex-center mb30">
                  <Button className="w100p" onClick={this.handleInfiniteOnLoad} type="link">加载更多</Button>
                </div>
              </Spin>
            </div>
        }

        <Footer style={{ textAlign: 'center' }}>
          <div className="color-333">Viva la Vida ©2020</div>
          <Link to="/support"><div className="color-666">技术支持：益科技</div></Link>
        </Footer>
        <ReportModal
          visible={visibleReport}
          onCancel={() => this.handleCancel()}
          reportItem={selectedReportItem}
        // reloadHomeList={this.reloadHomeList}
        ></ReportModal>
        <SigninModal
          visible={visible}
          onCancel={() => this.handleCancel()}
          reloadHomeList={this.reloadHomeList}
        ></SigninModal>
        <PostPictureModal
          visible={postPictureVisible}
          onCancel={() => this.handleCancelPostPicture()}
          reloadHomeList={this.reloadHomeList}
          draftCreation={draftCreation}
          isDraft={isDraft}
        ></PostPictureModal>
        <PostDiaryModal
          visible={postDiaryVisible}
          onCancel={() => this.handleCancelPostDiary()}
          reloadHomeList={this.reloadHomeList}
          draftDiary={draftDiary}
          isDraft={isDraft}
        ></PostDiaryModal>
        <DiaryDetailModal
          visible={visibleDiary}
          onCancel={() => this.handleCancel()}
          diary={selectDiary}
          toSignin={this.toSignin}
        ></DiaryDetailModal>
        <CreationDetailModal
          visible={visibleCreation}
          onCancel={() => this.handleCancel()}
          creation={selectCreation}
          toSignin={this.toSignin}
        ></CreationDetailModal>
      </Layout>
    )
  }
}

// 暴露组件名称
export default HocMedia(Home);
