import React from 'react'
import moment from 'moment'
import NavigationBar from '../component/NavigationBar'
import VLabel from '../component/VLabel'
import VButton from '../component/VButton'
import VMenu from '../component/VMenu'
import { List, Avatar, Spin, Typography } from 'antd'
import CreationDetailModal from '../component/CreationDetailModal'
import DiaryDetailModal from '../component/DiaryDetailModal'
import { UserOutlined, HeartOutlined, MessageOutlined } from '@ant-design/icons';
import { getQuery } from '../utils/utils';
import HocMedia from '../utils/HocMedia'
const AV = require('leancloud-storage');
const Paragraph = Typography.Paragraph
class MuseumDetail extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visibleCreation: false,
      visibleDiary: false,
      creations: [],
      diaries: [],
      current: 'creation'
    }
  }
  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchCreationList()
    this.fetchDiaryList()
    this.fetchMuseum()
  }
  //1. 路由配置
  //2. 起始页面：传递参数，触发跳转：this.props.history.push('目标页面的路由?key1=value1&key2=value2')
  //3. 目标页面：获取url中的参数，props.location.search

  fetchCreationList() {
    //通过url获取传递的id参数
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    //查找到id对应的挑战项目
    const museum = AV.Object.createWithoutData('Museum', params.id)
    const query = new AV.Query('Creation');
    query.include('publisher')
    //设立查询条件，指查询当前id对应的挑战的所有创意
    query.equalTo('museum', museum)
    query.find().then(result => {
      that.setState({
        creations: result
      })
    })
  }
  fetchDiaryList() {
    //通过url获取传递的id参数
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    //查找到id对应的挑战项目
    const museum = AV.Object.createWithoutData('Museum', params.id)
    const query = new AV.Query('Diary');
    query.include('publisher')
    //设立查询条件，指查询当前id对应的挑战的所有创意
    query.equalTo('museum', museum)
    query.find().then(result => {
      that.setState({
        diaries: result
      })
    })
  }

  fetchMuseum() {
    const search = this.props.location.search
    const params = getQuery(search)
    const that = this
    const query = new AV.Query('Museum')
    query.equalTo('objectId', params.id)
    query.first().then(result => {
      that.setState({
        museum: result
      })
    }).finally(i => {
      that.setState({
        loadingList: false
      })
    })
  }
  handleCancel = (e) => {
    this.setState({
      visibleCreation: false,
      visibleDiary: false,
    });
  }
  showCreationDetail(item) {
    this.setState({
      visibleCreation: true,
      selectCreation: item,
    })
  }
  showDiaryDetail(item) {
    this.setState({
      visibleDiary: true,
      selectDiary: item
    })
  }
  handleMenuClick(key) {
    this.setState({
      current: key
    })
  }
  getRender(current) {
    const { creations, diaries, loadingList } = this.state
    if (current === 'creation') {
      return <Spin spinning={loadingList}>
        <div className="mainWidth">
          <List
            className="mt30"
            grid={{ gutter: 16, column: 3 }}
            dataSource={creations}
            pagination={{
              pageSize: 9
            }}
            renderItem={item => (
              <List.Item>
                <div className="card-wrap">
                  <img className="card-picture cursor-pointer" alt="" src={item.attributes.urls[0]} onClick={() => this.showCreationDetail(item)} />
                  <div className="challengedetail-content-wrap">
                    <div className="challengedetail-content">{item.attributes.content}</div>
                    <div className="publisher-wrap">
                      <div className="publisher-info">
                        <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                        <div className="publisher-name">{item.attributes.publisher.attributes.username}</div>
                      </div>
                      <div className="publisher-focus">
                        <VButton className="follow-button" type="ghost" title="关注" />
                      </div>
                    </div>
                  </div>
                </div>
              </List.Item>
            )}
          />
        </div>
      </Spin>
    } else {
      return <Spin spinning={loadingList}>
        <div className="mainWidth">
          <List
            dataSource={diaries}
            pagination={{
              pageSize: 9
            }}
            renderItem={item => (
              <List.Item>
                <div className="profile-diary-item-wrap">
                  <div className="feeds-publisher-wrap">
                    <div className="feeds-publisher-info">
                      <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} src={item.attributes.publisher.attributes.avatar && item.attributes.publisher.attributes.avatar.thumbnailURL(32, 32)} />
                      <div className="ml10">
                        <div className="feeds-publisher-name">{item.attributes.publisher.attributes.username}</div>
                        <div className="feeds-publisher-date">{moment(item.createdAt).format('YYYY-MM-DD HH:mm')}</div>
                      </div>
                    </div>
                    <div className="publisher-focus">
                      <div className="flex-sb">
                        <HeartOutlined className="fs16 mr20" />
                        <MessageOutlined className="fs16" />
                      </div>
                    </div>
                  </div>
                  <div className="profile-diary-title">{item.attributes.title}</div>
                  <Paragraph className="profile-diary-content" ellipsis={{ rows: 2 }}>{item.attributes.textContent}</Paragraph>
                  <div className="theme-color fs14 cursor-pointer" onClick={() => this.showDiaryDetail(item)}>查看完整内容</div>
                  {/* <div className="challengedetail-content"dangerouslySetInnerHTML={{__html: `${item.attributes.content}`}} /> */}
                </div>
              </List.Item>
            )}
          />
        </div>
      </Spin>
    }
  }
  render() {
    const { museum, visibleCreation, creations, diaries, visibleDiary, selectCreation, selectDiary, current } = this.state;
    const { isMobile } = this.props
    return (
      <div>
        <NavigationBar history={this.props.history} />
        <div className="container-wrap">
          {isMobile
            ?
            //移动端
            <div className="content-wrap-mobile">
              <div className="challenge-up-wrap-mobile">
                <div className="name text-align-center">{museum && museum.attributes.name}</div>
                <img className="picture-mobile padding-column-10" alt="" src={museum && museum.attributes.picture.thumbnailURL(50, 50)} />
                <div className="flex-row">
                  <div className="number">{creations.length + diaries.length}篇</div>
                  <div className="popularity">日记/创作</div>
                </div>
              </div>
              <div className="challenge-down-wrap-mobile padding-column-10">{museum && museum.attributes.description}</div>
            </div>
            :
            //PC端
            <div className="content-wrap">
              <img className="picture" alt="" src={museum && museum.attributes.picture.thumbnailURL(50, 50)} />
              <div className="content">
                <div className="up-wrap">
                  <div className="name">{museum && museum.attributes.name}</div>
                </div>
                <div className="middle-wrap">
                  <div className="number">{creations.length + diaries.length}篇</div>
                  <div className="popularity">日记/创作</div>
                </div>
                <div className="down-wrap">{museum && museum.attributes.description}</div>
              </div>
            </div>
          }

          <div className="profile-menu-container-wrap mt20 mainWidth">
            <VMenu className="profile-menu">
              <VLabel title="创作类" backgroundClassName="mr50" showLine={current === 'creation'} onClick={() => this.handleMenuClick('creation')}></VLabel>
              <VLabel title="日记类" showLine={current === 'diary'} onClick={() => this.handleMenuClick('diary')}></VLabel>
            </VMenu>
            {
              this.getRender(current)
            }
          </div>
        </div>
        <CreationDetailModal
          visible={visibleCreation}
          onCancel={() => this.handleCancel()}
          creation={selectCreation}
        ></CreationDetailModal>
        <DiaryDetailModal
          visible={visibleDiary}
          onCancel={() => this.handleCancel()}
          diary={selectDiary}
        ></DiaryDetailModal>
      </div>
    )
  }
}

export default HocMedia(MuseumDetail)