import React from 'react';
import { Modal, Radio } from 'antd';
import VButton from '../VButton'
import styles from './index.module.css';
import logo from '../../resources/logo-dark.png';
import { UserOutlined } from '@ant-design/icons';
import { ReportMap } from '../../utils/utils'
const AV = require('leancloud-storage')

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: 'SheHuangXinXi'
    };
  }
  onChange = e => {
    this.setState({
      value: e.target.value,
    })
  }
  report() {
    const { reportItem, onCancel } = this.props
    const { value } = this.state
    console.log(reportItem)
    const obj = new AV.Object('Report')
    obj.set('category', reportItem.reportType) 
    if (reportItem.reportType === 'creation') {
      const creation = AV.Object.createWithoutData('Creation', reportItem.id)
      obj.set('creation', creation)
      obj.set('reported', reportItem.attributes.publisher)
    } else if (reportItem.reportType === 'diary') {
      const diary = AV.Object.createWithoutData('Diary', reportItem.id)
      obj.set('diary', diary)
      obj.set('reported', reportItem.attributes.publisher)
    } else if (reportItem.reportType === 'comment') {
      const comment = AV.Object.createWithoutData('Comment', reportItem.id)
      obj.set('comment', comment)
      obj.set('reported', reportItem.attributes.sender)
    }
    obj.set('reason', ReportMap[value].text)    
    obj.set('reporter', AV.User.current())
    obj.save().then(res => {
      onCancel()
      Modal.success({
        title: '举报成功',
        content: '感谢您的监督与反馈',
      })
    })
  }
  render() {
    const { visible, onCancel } = this.props
    const { value } = this.state

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <Radio.Group onChange={this.onChange} value={value}>
            {

              Object.values(ReportMap).map(i => {
                return <div key={i.value} className={styles.radioWrap}>
                  <Radio value={i.value}>{i.text}</Radio>
                </div>
              })
            }
          </Radio.Group>
        </div>
        <div className={styles.footer}>
          <VButton title="取消" backClass={styles.cancel} titleClass={styles.cancelTitle} onClick={onCancel} />
          <VButton title="确定" backClass={styles.report} onClick={() => this.report()} />
        </div>
      </div>
    )
  }
}

export default class Index extends React.Component {
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        // style={{ width: 300, height: 500 }}
        width={300}
        title="选择举报原因"
        visible={visible}
        onCancel={onCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Content {...this.props} />
      </Modal>
    );
  }
}