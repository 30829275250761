import React from 'react';
import { Modal, Input, Checkbox, message } from 'antd';
import VButton from '../VButton'
import styles from './index.module.css';
import logo from '../../resources/logo-dark.png';
import { UserOutlined, KeyOutlined, MailOutlined } from '@ant-design/icons';
import { isValidPwd, isEmail } from '../../utils/utils'
const AV = require('leancloud-storage')

class Content extends React.Component {
  constructor(props) {
    super(props)
    this.state = { current: 'signin' };
  }
  handleMenuClick = key => {
    // console.log('click ', e);
    this.setState({ current: key });
  };
  signin() {
    const { email, password } = this.state
    const { onCancel, reloadHomeList } = this.props
    //确保用户必须输入邮箱和密码
    console.log(email)
    console.log(password)
    if (!email || !password) {
      message.warn('请将信息输入完整')
      return
    }
    if (!isEmail(email)) {
      message.warn('请输入正确的邮箱')
      return
    }
    AV.User.loginWithEmail(email, password).then((user) => {
      if (user.attributes.status) {
        Modal.error({
          title: '该账户无法登录',
          content: '该用户涉及违规，暂时无法登录，如有问题请联系管理员',
        });
        AV.User.logOut()
        return
      }
      // 登录成功
      message.success('登录成功')
      if (user.attributes.avatar) {
        localStorage.setItem("avatar", user.attributes.avatar.thumbnailURL(32, 32))
      }
      onCancel()
      if (reloadHomeList) {
        reloadHomeList()
      }
    }, (error) => {
      // 登录失败（可能是密码错误）
      if (error.code === 210) {
        message.error('邮箱与密码不匹配')
      } else if (error.code === 216) {
        message.error('邮箱未验证，请前往您的邮箱验证')
      } else {
        message.error('登录失败，请稍后重试')
      }

    });
  }
  //保存用户输入的email
  changeEmail(e) {
    this.setState({
      email: e.currentTarget.value
    })
  }
  //保存用户输入的password
  changePassword(e) {
    this.setState({
      password: e.currentTarget.value
    })
  }
  //保存用户输入的userName
  changeUserName(e) {
    this.setState({
      userName: e.currentTarget.value
    })
  }
  //保存用户输入的confirmPassword
  changeConfirmPassword(e) {
    this.setState({
      confirmPassword: e.currentTarget.value
    })
  }
  //保存用户选中的checked状态
  changeCheckBox(e) {
    this.setState({
      checked: e.target.checked
    })
  }
  signup() {
    // 1.必须输入所有输入框的内容(a.拿到state中每个输入框对应的保存的值；b.判断这些值中是否至少有一个为空)
    // 2.密码和确认密码必须一致
    // 3.同意协议的checkbox必须勾选
    // 4.向leancloud提交注册的用户信息
    const that = this
    const { email, password, userName, confirmPassword, checked } = this.state

    //逻辑运算符&&与and ||或or !非not
    if (!email || !password || !userName || !confirmPassword) {
      message.warn('请将信息输入完整')
      return
    }
    if (password !== confirmPassword) {
      message.warn('两次密码输入不一致，请重新输入')
      return
    }
    if (!isEmail(email)) {
      message.warn('请输入正确的邮箱')
      return
    }
    if (!isValidPwd(password)) {
      message.warn('请输入8~16位数字或英文字母组成的密码')
      return
    }
    if (!checked) {
      message.warn('请先同意《用户协议》和《隐私政策》')
      return
    }
    const user = new AV.User()
    user.set('username', userName)
    user.set('password', password)
    user.set('email', email)
    user.signUp().then((i) => {
      if (i) {
        message.success('注册成功，请登录')
        that.setState({
          password: '',
          userName: '',
          confirmPassword: '',
          current: 'signin'
        })
      }
    }).catch((err) => {
      if (err.code === 203) {
        message.error('该邮箱已被注册，请更换新的邮箱')
      } else if (err.code === 202) {
        message.error('该用户名已存在，请更换新的昵称')
      } else {
        message.error('注册失败，请重新注册')
      }
    })
  }
  resetPassword() {
    const { email } = this.state
    if (!email) {
      message.warn('请输入邮箱')
      return
    }
    if (!isEmail(email)) {
      message.warn('请输入正确的邮箱')
      return
    }
    AV.User.requestPasswordReset(email);
    message.success('重置邮件已发送，请前往您的邮箱')
  }
  getRender(current) {
    if (current === 'signin') {
      return <div>
        <Input className={styles.input} placeholder='请输入邮箱' prefix={<MailOutlined />}
          onChange={(e) => this.changeEmail(e)}></Input>
        <Input.Password className={styles.input} placeholder='请输入密码' prefix={<KeyOutlined />}
          onChange={(e) => this.changePassword(e)}></Input.Password>
        <div className={styles.optionWrapper}>
          <Checkbox onChange={this.onChange}>自动登录</Checkbox>
          <div className={styles.forgot} onClick={() => this.resetPassword()}>忘记密码</div>
        </div>
        <VButton title='登录' onClick={() => this.signin()} />
      </div>
    } else {
      return <div>
        <Input className={styles.input} placeholder='请输入邮箱' prefix={<MailOutlined />}
          onChange={(e) => this.changeEmail(e)}></Input>
        <Input className={styles.input} placeholder='请设置用户名' prefix={<UserOutlined />}
          onChange={(e) => this.changeUserName(e)}></Input>
        <Input.Password className={styles.input} placeholder='请设置密码' prefix={<KeyOutlined />}
          onChange={(e) => this.changePassword(e)}></Input.Password>
        <Input.Password className={styles.input} placeholder='请再次输入密码' prefix={<KeyOutlined />}
          onChange={(e) => this.changeConfirmPassword(e)}></Input.Password>
        <div className={styles.privacyWrap}>
          <Checkbox className={styles.checkBox} onChange={(e) => this.changeCheckBox(e)} />
          <div>我同意并愿意遵守VIVA</div>
          <a href="https://lc-gluttony.s3.amazonaws.com/kYtddmLAUObY/cd049b7ff30cb1b4ad16.pdf/VIVA-%E7%94%A8%E6%88%B7%E5%8D%8F%E8%AE%AE11.8.pdf" target="_blank" rel="noopener noreferrer" className={styles.themeColor}>用户协议</a>
          <div>及</div>
          <a href="https://lc-gluttony.s3.amazonaws.com/kYtddmLAUObY/d2853eef5fe9b262dc8f.pdf/VIVA-%E9%9A%90%E7%A7%81%E6%94%BF%E7%AD%9611.8.pdf" target="_blank" rel="noopener noreferrer" className={styles.themeColor}>隐私政策</a>
        </div>
        <VButton title='注册' onClick={() => this.signup()} />
      </div>
    }
  }
  render() {
    const { current } = this.state
    return (
      <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.logo}>
              <img src={logo} alt="" width={"100%"} height={48} />
            </div>
            <div className={styles.menuWrap}>
              <div className={current === 'signin' ? styles.menuItemSelected : styles.menuItem} onClick={() => this.handleMenuClick("signin")}>登录</div>
              <div className={current === 'signup' ? styles.menuItemSelected : styles.menuItem} onClick={() => this.handleMenuClick("signup")}>注册</div>
            </div>
            {
              this.getRender(current)
            }
          </div>
      </div>
    )
  }
}

export default class Index extends React.Component {
  render() {
    const { visible, onCancel } = this.props;
    return (
      <Modal
        style={{ width: 300, minHeight: 800 }}
        visible={visible}
        onCancel={onCancel}
        footer={null}
        destroyOnClose={true}
      >
        <Content {...this.props} />
      </Modal>
    );
  }
}