import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom'
import { AppstoreOutlined } from '@ant-design/icons';
import VMenu from '../VMenu';
import SigninModal from '../SigninModal'
import VMenuItem from '../VMenuItem';
import styles from './index.module.css';
import logo from '../../resources/logo.png';
import defaultAvatar from '../../resources/avatar.png';
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');
const menuItem = {
  top: 0,
  borderBottom: 0,
  color: 'white',
  backgroundColor: '#040F0A'
}
class NavigationBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 'search',
      hovering: undefined,
      visible: false
    };
  }
  handleClick = e => {
    this.setState({
      current: e
    });
  };
  handleMenuTypeClick = e => {
    const { onMenuClicked } = this.props
    if (onMenuClicked) {
      onMenuClicked(e)
    } else {
      this.props.history.push(`/?type=${e}`)
    }
  };
  handleMouseEnter(e) {
    this.setState({
      hovering: e
    })
  }
  handleMouseLeave() {
    this.setState({
      hovering: undefined
    })
  }
  navigateToProfile() {
    const { toSignin = this.toSignin } = this.props
    if (AV.User.current()) {
      this.props.history.push('/profile')
    } else {
      if (toSignin) {
        toSignin()
      }
    }
  }
  navigateToHome() {
    this.props.history.push('/')
  }
  navigateToMessage() {
    this.props.history.push('/messages')
  }
  navigateToOfficial() {
    this.props.history.push('/official')
  }
  toSignin = () => {
    this.setState({
      visible: true
    })
  }
  handleCancel() {
    this.setState({
      visible: false
    })
  }
  render() {
    const { visible, hovering } = this.state;
    const { isMobile } = this.props;
    const avatar = localStorage.getItem('avatar')
    return (
      <div className={styles.navigation}>
        <div className={styles.navigationBar}>
          <Link to="/">
            <div style={{ marginTop: 10 }}>
              <img src={logo} alt="" width={180} height={24} />
            </div>
          </Link>
          <div className={styles.topMenuWrap}>
            <Menu style={menuItem} mode="horizontal">
              {/* <Menu.Item className={styles.menuItem} style={menuItem} key="search" icon={<MailOutlined />}>
                搜索
            </Menu.Item> */}
              {/* <Menu.Item className={styles.menuItem} style={menuItem} key="publish" icon={<AppstoreOutlined />}>
                发布
            </Menu.Item> */}
              <Menu.Item className={styles.menuItem} style={menuItem} key="message" icon={<AppstoreOutlined />} onClick={() => this.navigateToMessage()}>
                消息
              </Menu.Item>
            </Menu>
            <div className={styles.avatar} onClick={() => this.navigateToProfile()}>
              <img src={avatar ? avatar : defaultAvatar} alt='' className={styles.avatar} />
            </div>
          </div>
        </div>
        <div className={styles.navigationBarBottom}>
          {/* <div> */}
          <VMenu>
            <VMenuItem itemKey="square" defaultShow={isMobile} onMouseEnter={() => { this.handleMouseEnter('square') }} onClick={() => this.navigateToHome()}>广场</VMenuItem>
            <VMenuItem itemKey="focus" onClick={() => this.navigateToProfile()}>我的关注</VMenuItem>
            <VMenuItem itemKey="official" onMouseEnter={() => { this.handleMouseEnter('official') }} onClick={() => this.navigateToOfficial()}>官方主页</VMenuItem>
          </VMenu>
        </div>
        {isMobile ?
          <div className={styles.hoverMenuWrap}>
            <div className={styles.hoverMenuDiv}>
              <VMenu className={styles.hoverMenu}>
                <VMenuItem itemKey="emotion" backClassName={styles.subMenuItemMobile} onClick={() => this.handleMenuTypeClick("emotion")}>情感</VMenuItem>
                <VMenuItem itemKey="grow" backClassName={styles.subMenuItemMobile} onClick={() => this.handleMenuTypeClick("grow")}>成长</VMenuItem>
                <VMenuItem itemKey="life" backClassName={styles.subMenuItemMobile} onClick={() => this.handleMenuTypeClick("life")}>生活</VMenuItem>
                <VMenuItem itemKey="people" backClassName={styles.subMenuItemMobile} onClick={() => this.handleMenuTypeClick("people")}>人文</VMenuItem>
                <VMenuItem itemKey="creative" backClassName={styles.subMenuItemMobile} onClick={() => this.handleMenuTypeClick("creative")}>创意</VMenuItem>
              </VMenu>
            </div>
          </div>
          :
          (hovering &&
            <div className={styles.hoverMenuWrap} onMouseLeave={() => this.handleMouseLeave()}>
              {hovering === 'square' ?
                <div className={styles.hoverMenuDiv}>
                  <VMenu className={styles.hoverMenu}>
                    <VMenuItem itemKey="emotion" backClassName={styles.subMenuItem} onClick={() => this.handleMenuTypeClick("emotion")}>情感</VMenuItem>
                    <VMenuItem itemKey="grow" backClassName={styles.subMenuItem} onClick={() => this.handleMenuTypeClick("grow")}>成长</VMenuItem>
                    <VMenuItem itemKey="life" backClassName={styles.subMenuItem} onClick={() => this.handleMenuTypeClick("life")}>生活</VMenuItem>
                    <VMenuItem itemKey="people" backClassName={styles.subMenuItem} onClick={() => this.handleMenuTypeClick("people")}>人文</VMenuItem>
                    <VMenuItem itemKey="creative" backClassName={styles.subMenuItem} onClick={() => this.handleMenuTypeClick("creative")}>创意</VMenuItem>
                  </VMenu>
                </div>
                :
                <div className={styles.hoverMenuDiv}>
                  <VMenu className={styles.hoverMenu} onClick={this.handleClick}>
                    <VMenuItem itemKey="challenge" backClassName={styles.subMenuItem} to="/challenges">创意挑战</VMenuItem>
                    <VMenuItem itemKey="topic" backClassName={styles.subMenuItem} to="/topics">话题讨论</VMenuItem>
                    <VMenuItem itemKey="museum" backClassName={styles.subMenuItem} to="/museum">艺术馆</VMenuItem>
                  </VMenu>
                </div>
              }
            </div>)
        }
        <SigninModal
          visible={visible}
          onCancel={() => this.handleCancel()}
        ></SigninModal>
      </div >
    )
  }
}

export default HocMedia(NavigationBar);