import React from 'react';
import styles from './index.module.css';
import { Link } from 'react-router-dom'
import HocMedia from '../../utils/HocMedia'
class VMenuItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      key: props.itemKey
    }
  }
  render() {
    const { className, backClassName, onClick, onMouseEnter, style, to, defaultShow = false } = this.props;
    const { isMobile } = this.props;
    return (
      <div className={`${isMobile ? styles.menuItemMobile : styles.menuItem} ${className}`} style={style} onClick={onClick} onMouseEnter={onMouseEnter}>
        {
          to ?
          <Link to={to}>
            <div className={backClassName}>{this.props.children}</div>
          </Link>
          :
          <div className={backClassName}>{this.props.children}</div>
        }
        <div className={defaultShow ? styles.triangleMobile : styles.triangle}></div>
      </div>
    )
  }
}

export default HocMedia(VMenuItem)