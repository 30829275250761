import React from 'react'
import moment from 'moment'
import NavigationBar from '../../component/NavigationBar'
import VButton from '../../component/VButton'
import VLabel from '../../component/VLabel'
import { Input, message, Radio, DatePicker, Spin } from 'antd'
import defaultAvatar from '../../resources/avatar.png';
import HocMedia from '../../utils/HocMedia'
const AV = require('leancloud-storage');

class ProfileEdit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  componentDidMount() {
    this.fetchProfile()
  }
  fetchProfile() {
    const that = this
    this.setState({
      loading: true
    })
    const userQuery = new AV.Query('_User');
    userQuery.equalTo('objectId', AV.User.current().id)
    userQuery.first().then(user => {
      that.setState({
        ...user.attributes
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }
  changeUserName = (e) => {
    this.setState({
      username: e.target.value,
    });
  }
  changeLocation = (e) => {
    this.setState({
      location: e.target.value,
    });
  }
  changeBrief = (e) => {
    this.setState({
      brief: e.target.value,
    });
  }
  onGenderChange = (e) => {
    this.setState({
      gender: e.target.value,
    });
  }
  onBirthChange = (date, dateString) => {
    this.setState({
      birthday: date,
    });
  }
  navigateTo(id) {
    this.props.history.push(`/challengedetail?id=${id}`)
    // this.props.history.push(`/challengedetail/${id}`)
  }
  save = () => {
    const { username, gender, avatar, location, brief, birthday } = this.state
    if (!username) {
      message.warn('昵称不能为空')
      return
    }
    const user = AV.User.current()
    user.set('username', username)
    user.set('gender', gender)
    user.set('avatar', avatar)
    user.set('location', location)
    user.set('brief', brief)
    user.set('birthday', birthday)
    user.save().then(u => {
      message.success('修改成功')
    }, (error) => {
      message.error(error)
    })
  }
  cancel = () => {
    this.props.history.goBack()
  }
  render() {
    const { username, gender = 0, avatar, location, brief, birthday, loading } = this.state
    const { isMobile } = this.props
    return (
      <div>
        <NavigationBar history={this.props.history} />
        {isMobile ?
          //Mobile
          <Spin spinning={loading}>
            <div className="container-wrap">
              <VLabel title="编辑信息"></VLabel>
              <div className="profile-edit-wrap-mobile">
                <img className="profile-avatar" src={avatar ? avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                <div className="flex-sb-center mt30">
                  <div className="fs14 color-333">昵称</div>
                  <div className="input-bordered-mobile ml20">
                    <Input className="h40" value={username} bordered={false} onChange={(e) => this.changeUserName(e)}></Input>
                  </div>
                </div>
                <div className="flex-sb-center mt30">
                  <div className="fs14 color-333">性别</div>
                  <div className="ml20">
                    <Radio.Group onChange={this.onGenderChange} value={gender}>
                      <Radio value={1}>男</Radio>
                      <Radio value={2}>女</Radio>
                      <Radio value={0}>保密</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="flex-sb-center mt30">
                  <div className="fs14 color-333">生日</div>
                  <DatePicker className="input-bordered-mobile" value={moment(birthday)} onChange={this.onBirthChange} />
                </div>
                <div className="flex-sb-center mt30">
                  <div className="fs14 color-333">地区</div>
                  <div className="input-bordered-mobile ml20">
                    <Input className="h40" value={location} bordered={false} onChange={(e) => this.changeLocation(e)}></Input>
                  </div>
                </div>
                <div className="flex-sb-center mt30">
                  <div className="fs14 color-333">签名</div>
                  <div className="input-bordered-mobile ml20">
                    <Input className="h40" value={brief} bordered={false} onChange={(e) => this.changeBrief(e)}></Input>
                  </div>
                </div>
                <div className="flex-end mt30">
                  <VButton type="ghost" size="large" title="取消" onClick={this.cancel} />
                  <div className="ml20">
                    <VButton title="保存" onClick={this.save} />
                  </div>
                </div>
              </div>
            </div>
          </Spin>
          :
          //PC
          <Spin spinning={loading}>
            <div className="container-wrap">
              <VLabel title="编辑信息"></VLabel>
              <div className="profile-edit-wrap">
                <div className="profile-edit-left">
                  <img className="profile-avatar" src={avatar ? avatar.thumbnailURL(108, 108) : defaultAvatar} alt="" />
                </div>
                <div className="profile-edit-right" >
                  <div className="flex-sb-center">
                    <div className="fs14 color-333">昵称</div>
                    <div className="input-bordered ml20">
                      <Input className="h40" value={username} bordered={false} onChange={(e) => this.changeUserName(e)}></Input>
                    </div>
                  </div>
                  <div className="flex-sb-center mt30">
                    <div className="fs14 color-333">性别</div>
                    <div className="ml20 w366">
                      <Radio.Group onChange={this.onGenderChange} value={gender}>
                        <Radio value={1}>男</Radio>
                        <Radio value={2}>女</Radio>
                        <Radio value={0}>保密</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  <div className="flex-sb-center mt30">
                    <div className="fs14 color-333">生日</div>
                    <div className="ml20">
                      <DatePicker className="w366 input-bordered" value={moment(birthday)} onChange={this.onBirthChange} />
                    </div>
                  </div>
                  <div className="flex-sb-center mt30">
                    <div className="fs14 color-333">地区</div>
                    <div className="input-bordered ml20">
                      <Input className="h40" value={location} bordered={false} onChange={(e) => this.changeLocation(e)}></Input>
                    </div>
                  </div>
                  <div className="flex-sb-center mt30">
                    <div className="fs14 color-333">签名</div>
                    <div className="input-bordered ml20">
                      <Input className="h40" value={brief} bordered={false} onChange={(e) => this.changeBrief(e)}></Input>
                    </div>
                  </div>
                  <div className="flex-end mt30">
                    <VButton type="ghost" size="large" title="取消" onClick={this.cancel} />
                    <div className="ml20">
                      <VButton title="保存" onClick={this.save} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        }
      </div>
    )
  }
}

export default HocMedia(ProfileEdit)