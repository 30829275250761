import 'braft-editor/dist/index.css'
import React from 'react';
import BraftEditor from 'braft-editor'
import { ContentUtils } from 'braft-utils'
// import { ImageUtils } from 'braft-finder'
import { Modal, Input, Upload, message, Popover } from 'antd';
import { PictureFilled, EnvironmentOutlined, TagOutlined } from '@ant-design/icons';
import VButton from '../VButton'
import styles from './index.module.css';
import './extension.css'
import { getBase64, TagMap } from '../../utils/utils'
const AV = require('leancloud-storage')

export default class PostDiaryModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      editorState: BraftEditor.createEditorState(null),
      hovered: false,
      hoveredLocation: false
    };
  }

  componentDidUpdate(nextProps) {
    //加载草稿内容
    if (this.props.visible === true && nextProps.visible === false && this.props.draftDiary && this.props.isDraft) {
      this.setDiary()
    }
  }

  setDiary() {
    const draftDiary = this.props.draftDiary.attributes
    this.setState({
      title: draftDiary.title,
      editorState: BraftEditor.createEditorState(draftDiary.content),
      location: draftDiary.location,
      type: draftDiary.type,
    })
  }

  clearEditor() {
    this.setState({
      editorState: BraftEditor.createEditorState(null),
    })
  }

  handleChange = (editorState) => {
    this.setState({ editorState })
  }
  handleHoverChange = (visible) => {
    this.setState({
      hovered: visible
    })
  }
  handleHoverLocationChange = (visible) => {
    this.setState({
      hoveredLocation: visible
    })
  }
  uploadFile = async (file) => {
    const fileStr = await getBase64(file)
    const data = { base64: fileStr };
    const avfile = new AV.File(file.name, data)
    message.loading('图片上传中', avfile.id)
    await avfile.save().then(res => {
      message.success('上传成功', avfile.id)
      return res.thumbnailURL(50, 50)
    }, (error) => {
      console.log(error)
      // message.error(error)
      // 保存失败，可能是文件无法被读取，或者上传过程中出现问题
    })
  }

  uploadHandler = async (param) => {
    const file = param.file
    const that = this
    if (!file) {
      return false
    }
    const fileStr = await getBase64(file)
    const data = { base64: fileStr };
    const avfile = new AV.File(file.name, data)
    message.loading('图片上传中', avfile.id)
    avfile.save().then(res => {
      message.success('上传成功', avfile.id)
      that.setState({
        editorState: ContentUtils.insertMedias(this.state.editorState, [{
          type: 'IMAGE',
          url: res.thumbnailURL(50, 50)
        }])
      })
    }, (error) => {
      console.log(error)
      // message.error(error)
      // 保存失败，可能是文件无法被读取，或者上传过程中出现问题
    })
  }
  changeTitle(e) {
    this.setState({
      title: e.currentTarget.value
    })
  }
  changeLocation(e) {
    this.setState({
      location: e.currentTarget.value
    })
  }
  selectType = (type) => {
    this.setState({
      type: TagMap[type].value
    })
  }
  post() {
    const { onCancel, reloadHomeList, topic, draftDiary, isDraft } = this.props
    const { title, editorState, location, type } = this.state
    const content = editorState.toHTML()
    const textContent = editorState.toText()
    const that = this
    if (!content || content==='<p></p>') {
      message.warn('请输入正文')
      return
    }
    if (!type) {
      message.warn('请选择分类标签')
      return
    }
    const diary = new AV.Object('Diary')
    diary.set('content', content)
    diary.set('textContent', textContent)
    diary.set('publisher', AV.User.current())
    if (title) {
      diary.set('title', title)
    }
    if (location) {
      diary.set('location', location)
    }
    if (type) {
      diary.set('type', type)
    }   
    if (topic) {
      diary.set('topic', topic)
    }
    diary.save().then(res => {
      message.success('发送成功')
      reloadHomeList()
      this.setState({
        title: undefined,
        content: undefined,
        fileList: [],
        location: undefined,
        type: undefined,
      })
      if (isDraft) {
        that.updateDraft(draftDiary)
      }      
    }).finally(() => {
      onCancel()
    })
  }
  updateDraft(draftDiary) {
    const draft = AV.Object.createWithoutData('Draft', draftDiary.id)
    draft.set('published', true)
    draft.save()
  }
  saveDraft() {
    const { onCancel } = this.props
    const { title, editorState, location, type } = this.state
    const content = editorState.toHTML()
    const textContent = editorState.toText()

    if (!content || content==='<p></p>') {
      message.warn('请输入正文')
      return
    }
    
    const draft = new AV.Object('Draft')
    draft.set('content', content)
    draft.set('textContent', textContent)
    draft.set('type', type)
    draft.set('category', 'diary')
    draft.set('publisher', AV.User.current())
    if (title) {
      draft.set('title', title)
    }
    if (location) {
      draft.set('location', location)
    }
    if (type) {
      draft.set('type', type)
    }
    draft.save().then(res => {
      message.success('草稿已保存')
    }).finally(() => {
      onCancel()
    })
  }
  render() {
    const { visible, onCancel, isDraft } = this.props
    const { location, type, hovered, hoveredLocation, title} = this.state
    const controls = ['emoji', 'text-align', 'bold']
    const extendControls = [
      {
        key: 'antd-uploader',
        type: 'component',
        component: (
          <Upload
            accept="image/*"
            showUploadList={false}
            customRequest={this.uploadHandler}
          >
            {/* 这里的按钮最好加上type="button"，以避免在表单容器中触发表单提交，用Antd的Button组件则无需如此 */}
            <button type="button" className="control-item button upload-button" data-title="插入图片">
              <PictureFilled />
            </button>
          </Upload>
        )
      }
    ]
    return (
      <div className={`${styles.container} post-picture`}>
        <Modal
          style={{ width: 500, maxHeight: 500 }}
          title="发日记"
          visible={visible}
          maskClosable={false}
          onCancel={() => {
            this.clearEditor()
            onCancel()
          }}
          footer={null}
        >
          <div className={styles.content}>
            <Input className={styles.input} bordered={false} placeholder='输入作品名称（可为空）'
              onChange={(e) => this.changeTitle(e)} value={isDraft ? title : undefined}></Input>
            <div className={styles.line}></div>
            <div className="editor-wrapper">
              <BraftEditor
                value={this.state.editorState}
                placeholder='输入正文'
                onChange={this.handleChange}
                controls={controls}
                extendControls={extendControls}
              />
            </div>
            <div className={styles.options}>
              <Popover
                content={
                  <div className={styles.options}>
                    <Input className={styles.input} bordered={false} placeholder='输入地点'
                      onChange={(e) => this.changeLocation(e)} value={isDraft ? location : undefined}></Input>
                  </div>
                }
                trigger="hover"
                visible={hoveredLocation}
                onVisibleChange={this.handleHoverLocationChange}
              >
                <div className={styles.iconTextWrap}>
                  <EnvironmentOutlined className={styles.icon} />
                  <div className={styles.iconText}>{location || '你在哪里'}</div>
                </div>
              </Popover>

              <Popover
                content={
                  <div>
                    {
                      Object.values(TagMap).map(i => {
                        return <div key={i.value} className={styles.popoverItem} onClick={() => this.selectType(i.value)}>{i.text}</div>
                      })
                    }
                  </div>
                }
                trigger="hover"
                visible={hovered}
                onVisibleChange={this.handleHoverChange}
              >
                <div className={styles.iconTextWrap}>
                  <TagOutlined className={styles.icon} />
                  <div className={styles.iconText}>{(TagMap[type] && TagMap[type].text) || '添加分类标签'}</div>
                </div>
              </Popover>
            </div>
            <div className={styles.footer}>
              <VButton title="取消" backClass={styles.cancel} titleClass={styles.cancelTitle} onClick={onCancel} />
              <div className={styles.footerRight}>
                <VButton title="保存" type="ghost" backClass={styles.post} titleClass={styles.postTitle} onClick={() => this.saveDraft()} />
                <VButton title="发送" backClass={styles.save} onClick={() => this.post()} />
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}