import React from 'react'
import NavigationBar from '../component/NavigationBar'
import VButton from '../component/VButton'
import VLabel from '../component/VLabel'
import { List, Spin } from 'antd'
import HocMedia from '../utils/HocMedia'
const AV = require('leancloud-storage');

class ChallengeList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: false
    }
  }

  //组件加载后执行的函数 - 组件的生命周期函数之一
  componentDidMount() {
    this.fetchChallenges()
  }

  fetchChallenges() {
    const that = this
    that.setState({
      loading: true
    })
    const query = new AV.Query('Challenge');
    query.addAscending('index')
    query.find().then(result => {
      that.setState({
        data: result
      })
    }).finally(() => {
      that.setState({
        loading: false
      })
    })
  }

  navigateTo(id) {
    this.props.history.push(`/challengedetail?id=${id}`)
  }

  render() {
    const { data, loading } = this.state;
    const { isMobile } = this.props;
    return (
      <Spin spinning={loading} >
        <div>
          <NavigationBar history={this.props.history} />
          {isMobile ?
            // 移动端
            <div className="container-wrap-mobile">
              <VLabel title="创意挑战"></VLabel>
              <List
                dataSource={data}
                pagination={{
                  pageSize: 5
                }}
                renderItem={item => (
                  <List.Item>
                    <div className="content-wrap-mobile">
                      <div className="challenge-up-wrap-mobile">
                        <div className="name text-align-center">{item.attributes.title}</div>
                        <img className="picture-mobile padding-column-10" alt="" src={item.attributes.picture.thumbnailURL(50, 50)} />
                        <div className="challenge-down-wrap-mobile padding-column-10">
                          {item.attributes.content}
                        </div>
                        <VButton title="查看详情" onClick={() => this.navigateTo(item.id)} />
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
            :
            //PC端
            <div className="container-wrap">
              <VLabel title="创意挑战"></VLabel>
              <List
                dataSource={data}
                pagination={{
                  pageSize: 5
                }}
                renderItem={item => (
                  <List.Item>
                    <div className="content-wrap">
                      <img className="picture" alt="" src={item.attributes.picture.thumbnailURL(50, 50)} />
                      <div className="content">
                        <div className="up-wrap">
                          <div className="name">{item.attributes.title}</div>

                          {/* onClick={this.navigateTo.bind(this)}
                      onClick={() => this.navigateTo()} */}

                          <VButton title="查看详情" onClick={() => this.navigateTo(item.id)} />
                        </div>
                        <div className="middle-wrap">
                          {/* <div className="number">参与人数:50</div>
                        <div className="popularity">热度：999</div> */}
                        </div>
                        <div className="down-wrap">
                          {item.attributes.content}
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          }
        </div>
      </Spin>
    )
  }
}

export default HocMedia(ChallengeList)